import {useState} from "react";

export default function useRole() {
  const getRole = () => {
    return localStorage.getItem("role");
  };

  const [role, setRole] = useState(getRole);

  const saveRole = (role) => {
    localStorage.setItem("role", role);
    setRole(role);
  };

  return {
    setRole: saveRole,
    role,
  };
}
