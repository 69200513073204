import React, {useState} from 'react';
import {Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle} from "@mui/material";
import * as PropTypes from "prop-types";

import DialogX from "../../components/DialogX";


function MyDialog(props) {
  const [open, setOpen] = useState(false);
  const [dialogDescription, setDialogDescription] = useState("");
  const [dialogTitle, setDialogTitle] = useState("Login");

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Button variant="outlined" onClick={() => {
        setDialogDescription("Username & Password salah");
        handleClickOpen();
      }}>
        Open alert dialog
      </Button>
      <Button variant="outlined" onClick={() => {
        setDialogDescription("Hak akses harus diisi");
        handleClickOpen();
      }}>
        Open alert dialog
      </Button>
      <DialogX open={open} onClose={handleClose} description={dialogDescription} title={dialogTitle}/>
    </div>
  );
}

export default MyDialog;