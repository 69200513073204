import React, {useState} from 'react';

import {Paper, Box, Button, Typography, TextField} from "@mui/material";

import "./ChangePassword.css";
import {useHistory} from "react-router-dom";
import useUser from "../../user/use";
import axios from "axios";
import DialogX from "../../components/DialogX";

function ChangePassword(props) {
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword1, setNewPassword1] = useState("");
  const [newPassword2, setNewPassword2] = useState("");

  const history = useHistory();
  const {
    state: {userId, role, token},
  } = useUser();

  const baseURL = process.env.REACT_APP_SERVER;

  /* Dialog Alert */
  const [open, setOpen] = useState(false);
  const [dialogDescription, setDialogDescription] = useState("");
  const [dialogTitle, setDialogTitle] = useState("Ganti Password");
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const changePassword = () => {

    // new password ada syarat2? atau di trim kah?

    // console.log("change password running");
    // console.log("oldPassword ", oldPassword);
    // console.log("newPassword1 ", newPassword1);
    // console.log("newPassword2 ", newPassword2);
    // new password tidak boleh kosong
    if (oldPassword.trim() === "" || newPassword1.trim() === "" || newPassword2.trim() === "") {
      // console.log("salah satu empty password, ngga boleh");
      setDialogDescription("Semua isian harus diisi");
      setOpen(true);
      return;
    }

    // check newpassword1 = newpassword2
    // if not, show dialog box
    if (!(newPassword1 === newPassword2)) {
      // console.log("password baru harus sama dengan password baru (ulang) sama");
      setDialogDescription("Pengulangan password baru harus sama");
      setOpen(true);
      return;
    }

    // change password
    const dataPost = {
      secret_token: token,
      oldpassword: oldPassword.trim(),
      newpassword: newPassword1.trim()
    };
    axios.post(baseURL + "/password/change", dataPost)
      .then(response => {
        // console.log(response.data.message);
        if (response.data.message === "Success") {
          // console.log('sudah terganti loh');
          setDialogDescription("Berhasil ganti password");
          setOpen(true);
          return;
        } else if (response.data.message === "Wrong Old Password") {
          // console.log("wrong previous password");
          setDialogDescription("Password Lama salah");
          setOpen(true);
          return;
        } else if (response.data.message === "User not Found") {
          // console.log("User not found");
          setDialogDescription("Tidak ditemukan user yang diinput");
          setOpen(true);
          return;
        }
      })
      .catch(err => {
        console.log(err);
      });

    // if return false, show in dialog

    // if success, show dialog
  };

  return (
    <div className="changepassword">
      <Typography variant="h5">Ganti Password</Typography>
      <p></p>
      <Paper sx={{width: "600px", padding: "25px"}}>
        <Box sx={{display: "flex", flexDirection: "column"}}>
          <Box sx={{
            display: "flex",
            alignItems: "center",
            flexDirection: "row",
            marginTop: "5px",
            marginBottom: "5px",
            justifyContent: "center"
          }}>
            <Box sx={{width: "175px"}}><Typography variant="body1">Password
              Lama:</Typography></Box>
            <TextField id="passwordLama" label="Password Lama" variant="outlined" size="small" value={oldPassword}
                       onChange={(e) => setOldPassword(e.target.value)}/>
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              flexDirection: "row",
              marginTop: "5px",
              marginBottom: "5px",
              justifyContent: "center"
            }}>
            <Box sx={{width: "175px"}}><Typography variant="body1">Password Baru:</Typography></Box>
            <TextField id="passwordBaru1" label="Password Baru" variant="outlined" size="small" value={newPassword1}
                       onChange={(e) => setNewPassword1(e.target.value)}/>
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              flexDirection: "row",
              marginTop: "5px",
              marginBottom: "5px",
              justifyContent: "center"
            }}>
            <Box sx={{width: "175px"}}><Typography variant="body1">Password Baru (Ulangi):</Typography></Box>
            <TextField id="passwordBaru2" label="Password Baru (Ulangi)" variant="outlined" size="small"
                       value={newPassword2} onChange={(e) => setNewPassword2(e.target.value)}/>
          </Box>
          <Box sx={{display: "flex", flexDirection: "row", justifyContent: "center", marginTop: "10px"}}>
            <Button variant="contained" sx={{marginLeft: "5px", marginRight: "5px"}} onClick={changePassword}>Ganti
              Password</Button>
            <Button variant="contained" sx={{marginLeft: "5px", marginRight: "5px"}}
                    onClick={() => history.goBack()}>Batal</Button>
          </Box>
        </Box>
      </Paper>
      <DialogX open={open} onClose={handleClose} description={dialogDescription} title={dialogTitle}/>
    </div>
  );
}

export default ChangePassword;
