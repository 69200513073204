import {Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle} from "@mui/material";
import * as PropTypes from "prop-types";
import React from "react";

function DialogY(props) {
  const description = props.description;
  const title = props.title;

  return <Dialog
    open={props.open}
    onClose={props.onClose}
    aria-labelledby="alert-dialog-title"
    aria-describedby="alert-dialog-description"
  >
    <DialogTitle id="alert-dialog-title">
      {title}
    </DialogTitle>
    <DialogContent>
      <DialogContentText id="alert-dialog-description">
        {description}
      </DialogContentText>
    </DialogContent>
    <DialogActions>
      <Button onClick={props.onAction}>OK</Button>
      <Button onClick={props.onClose} autoFocus>
        Batal
      </Button>
    </DialogActions>
  </Dialog>;
}

DialogY.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  onAction: PropTypes.func,
  description: PropTypes.string,
  title: PropTypes.string
};

export default DialogY;