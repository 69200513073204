import React, {useEffect, useState} from "react";
import {useFetch} from "../../hooks/useFetch";
import {Link, useHistory, useParams} from "react-router-dom";
import axios from "axios";

import Attachment from "../../components/Attachment";


// import * as React from 'react';
import Stack from "@mui/material/Stack";
import {Card, CardActionArea, CardContent, Paper} from "@mui/material";
import {Accordion, AccordionDetails, AccordionSummary, Grid} from "@mui/material";
import {Divider} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import "./SchoolCategories.css";
import {Typography, Button, Box} from "@mui/material";

import {useSchoolInfo} from "../../hooks/useSchoolInfo";
import useUser from "../../user/use";
import SchoolWidget from "../../components/SchoolWidget";


const Item = (props) => {
  return (
    <Box sx={{
      width: '50px',
      backgroundColor: `${props.backgroundColor}`,
      color: "white",
      marginLeft: "2px",
      marginRight: "2px",
      // margin: "auto",
      textAlign: "center",
      fontSize: "10px",
      paddingTop: "4px",
      paddingRight: "2px",
      paddingBottom: "4px",
      paddingLeft: "2px",
    }}>
      {props.children}
    </Box>
  );
};

const MyitemPRAP = ({
                      no = "",
                      category = "",
                      em1 = "-",
                      audit1 = "-",
                      audit2 = "-",
                      em2 = "-",
                      audit3 = "-",
                      max = "-",
                      link,
                      sEm1 = "-",
                      sAudit1 = "-",
                      sAudit2 = "-",
                      sEm2 = "-",
                      sAudit3 = "-",
                      sMax = "-",
                      qEm1 = "-", qAudit1 = "-", qAudit2 = "-", qEm2 = "-", qAudit3 = "-", qTotal = "-",
                      showAudit1 = true,
                      appType = "SRA"
                    }) => {
  return (
    // <Paper elevation={0}
    //        sx={{padding: "10px", marginLeft: "15px", marginRight: "15px", marginTop: "10px", marginBottom: "10px"}}>
    //   <Box sx={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
    //     {/*<Box sx={{width: '60px', textAlign: 'center', marginLeft: '4px', marginRight: '4px'}}>*/}
    //     {/*  {no}*/}
    //     {/*</Box>*/}
    //     <Box sx={{width: "680px", paddingLeft: "5px", paddingRight: "5px", fontSize: "20px"}}>
    //       <Typography variant="h7"
    //                   sx={{fontSize: '18px'}}>{category.toUpperCase()} --- {em1} {audit1} {em2} {audit2} {max}</Typography>
    //     </Box>
    //
    //   </Box>
    // </Paper>

    <Paper elevation={0}
           sx={{padding: "10px", marginLeft: "15px", marginRight: "15px", marginTop: "10px", marginBottom: "10px"}}>
      <Box sx={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
        {/*<Box sx={{width: '60px', textAlign: 'center', marginLeft: '4px', marginRight: '4px'}}>*/}
        {/*  {no}*/}
        {/*</Box>*/}
        <Box sx={{width: "680px", paddingLeft: "5px", paddingRight: "5px", fontSize: "20px"}}>
          <Typography variant="h7" sx={{fontSize: '18px'}}>{category.toUpperCase()}</Typography>
        </Box>
        <Box sx={{display: 'flex', flexDirection: 'column'}}>
          <Box sx={{display: 'flex', flexDirection: 'row', marginTop: '2px', marginBottom: '2px'}}>
            <Item backgroundColor="#006638">EM1: {em1}</Item>
            {/* operator sra show, operator pisa hidden */}
            {(appType === "PISA") && showAudit1 &&
              <Item backgroundColor="#006638">AU1: {audit1}</Item>
            }
            {(appType === "SRA" || appType === "PRAP") &&
              <Item backgroundColor="#006638">AU1: {audit1}</Item>
            }
            {/* operator pisa show */}
            {(appType === "PISA") &&
              < Item backgroundColor="#006638">AUDIT: {audit2}</Item>
            }
            <Item backgroundColor="#006638">EM2: {em2}</Item>
            <Item backgroundColor="#006638">AUF: {audit3}</Item>
            <Item backgroundColor="#006638">Maks: {max}</Item>
          </Box>
          {/*<Box sx={{display: 'flex', flexDirection: 'row', marginTop: '2px', marginBottom: '2px'}}>*/}
          {/*  <Item backgroundColor="#006638">Std: {sEm1}</Item>*/}
          {/*  {(appType === "PISA") && showAudit1 &&*/}
          {/*    <Item backgroundColor="#006638">Std: {sAudit1}</Item>*/}
          {/*  }*/}
          {/*  {(appType === "SRA" || appType === "PRAP") &&*/}
          {/*    <Item backgroundColor="#006638">Std: {sAudit1}</Item>*/}
          {/*  }*/}
          {/*  {(appType === "PISA") &&*/}
          {/*    <Item backgroundColor="#006638">Std: {sAudit2}</Item>*/}
          {/*  }*/}
          {/*  <Item backgroundColor="#006638">Std: {sEm2}</Item>*/}
          {/*  <Item backgroundColor="#006638">Std: {sAudit3}</Item>*/}
          {/*  <Item backgroundColor="#006638">Std: {sMax}</Item>*/}
          {/*</Box>*/}
          {/*// TODO: for now hide, but next need to show*/}
          {/*<Box sx={{display: 'flex', flexDirection: 'row', marginTop: '2px', marginBottom: '2px'}}>*/}
          {/*  <Item backgroundColor="#006638">{qEm1}</Item>*/}
          {/*  <Item backgroundColor="#00594c">{qAudit1}</Item>*/}
          {/*  <Item backgroundColor="#004a59">{qAudit2}</Item>*/}
          {/*  <Item backgroundColor="#00395b">{qEm2}</Item>*/}
          {/*  <Item backgroundColor="#00274f">{qAudit3}</Item>*/}
          {/*  <Item backgroundColor="#161438">Total: {qTotal}</Item>*/}
          {/*</Box>*/}
        </Box>
        <Box>
          {/*<Link to={link} style={{textDecoration: 'none'}}>*/}
          {/*  <Button variant="contained" size="small"*/}
          {/*          sx={{*/}
          {/*            marginLeft: "8px", marginRight: "8px", textDecoration: 'none',*/}
          {/*            borderRadius: "0px", boxShadow: "none"*/}
          {/*          }}>Lihat</Button>*/}
          {/*</Link>*/}
        </Box>
      </Box>
    </Paper>
  );
};

const MyitemSRA = ({
                     no = "",
                     category = "",
                     em1 = "-",
                     audit1 = "-",
                     audit2 = "-",
                     em2 = "-",
                     audit3 = "-",
                     max = "-",
                     link,
                     sEm1 = "-",
                     sAudit1 = "-",
                     sAudit2 = "-",
                     sEm2 = "-",
                     sAudit3 = "-",
                     sMax = "-",
                     qEm1 = "-", qAudit1 = "-", qAudit2 = "-", qEm2 = "-", qAudit3 = "-", qTotal = "-",
                     showAudit1 = true,
                     appType = "SRA"
                   }) => {
  return (
    <Paper elevation={0}
           sx={{padding: "10px", marginLeft: "15px", marginRight: "15px", marginTop: "10px", marginBottom: "10px"}}>
      <Box sx={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
        {/*<Box sx={{width: '60px', textAlign: 'center', marginLeft: '4px', marginRight: '4px'}}>*/}
        {/*  {no}*/}
        {/*</Box>*/}
        <Box sx={{width: "680px", paddingLeft: "5px", paddingRight: "5px", fontSize: "20px"}}>
          <Typography variant="h7" sx={{fontSize: '18px'}}>{category.toUpperCase()}</Typography>
        </Box>
        <Box sx={{display: 'flex', flexDirection: 'column'}}>
          <Box sx={{display: 'flex', flexDirection: 'row', marginTop: '2px', marginBottom: '2px'}}>
            <Item backgroundColor="#006638">EM1: {em1}</Item>
            {/* operator sra show, operator pisa hidden */}
            {(appType === "PISA") && showAudit1 &&
              <Item backgroundColor="#006638">AU1: {audit1}</Item>
            }
            {(appType === "SRA" || appType === "PRAP") &&
              <Item backgroundColor="#006638">AU1: {audit1}</Item>
            }
            {/* operator pisa show */}
            {(appType === "PISA") &&
              < Item backgroundColor="#006638">AUDIT: {audit2}</Item>
            }
            <Item backgroundColor="#006638">EM2: {em2}</Item>
            <Item backgroundColor="#006638">AUF: {audit3}</Item>
            <Item backgroundColor="#006638">Maks: {max}</Item>
          </Box>
          <Box sx={{display: 'flex', flexDirection: 'row', marginTop: '2px', marginBottom: '2px'}}>
            <Item backgroundColor="#006638">Std: {sEm1}</Item>
            {(appType === "PISA") && showAudit1 &&
              <Item backgroundColor="#006638">Std: {sAudit1}</Item>
            }
            {(appType === "SRA" || appType === "PRAP") &&
              <Item backgroundColor="#006638">Std: {sAudit1}</Item>
            }
            {(appType === "PISA") &&
              <Item backgroundColor="#006638">Std: {sAudit2}</Item>
            }
            <Item backgroundColor="#006638">Std: {sEm2}</Item>
            <Item backgroundColor="#006638">Std: {sAudit3}</Item>
            <Item backgroundColor="#006638">Std: {sMax}</Item>
          </Box>
          {/*// TODO: for now hide, but next need to show*/}
          {/*<Box sx={{display: 'flex', flexDirection: 'row', marginTop: '2px', marginBottom: '2px'}}>*/}
          {/*  <Item backgroundColor="#006638">{qEm1}</Item>*/}
          {/*  <Item backgroundColor="#00594c">{qAudit1}</Item>*/}
          {/*  <Item backgroundColor="#004a59">{qAudit2}</Item>*/}
          {/*  <Item backgroundColor="#00395b">{qEm2}</Item>*/}
          {/*  <Item backgroundColor="#00274f">{qAudit3}</Item>*/}
          {/*  <Item backgroundColor="#161438">Total: {qTotal}</Item>*/}
          {/*</Box>*/}
        </Box>
        <Box>
          <Link to={link} style={{textDecoration: 'none'}}>
            <Button variant="contained" size="small"
                    sx={{
                      marginLeft: "8px", marginRight: "8px", textDecoration: 'none',
                      borderRadius: "0px", boxShadow: "none"
                    }}>Lihat</Button>
          </Link>
        </Box>
      </Box>
    </Paper>
  );
};

const MyitemPISA = ({
                      no = "",
                      category = "",
                      em1 = "-",
                      audit1 = "-",
                      audit2 = "-",
                      em2 = "-",
                      audit3 = "-",
                      max = "-",
                      link,
                      sEm1 = "-",
                      sAudit1 = "-",
                      sAudit2 = "-",
                      sEm2 = "-",
                      sAudit3 = "-",
                      sMax = "-",
                      qEm1 = "-", qAudit1 = "-", qAudit2 = "-", qEm2 = "-", qAudit3 = "-", qTotal = "-",
                      showAudit1 = true,
                      appType = "SRA"
                    }) => {
  return (
    <Paper elevation={0}
           sx={{padding: "10px", marginLeft: "15px", marginRight: "15px", marginTop: "10px", marginBottom: "10px"}}>
      <Box sx={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
        {/*<Box sx={{width: '60px', textAlign: 'center', marginLeft: '4px', marginRight: '4px'}}>*/}
        {/*  {no}*/}
        {/*</Box>*/}
        <Box sx={{width: "680px", paddingLeft: "5px", paddingRight: "5px", fontSize: "20px"}}>
          <Typography variant="h7" sx={{fontSize: '18px'}}>{category.toUpperCase()}</Typography>
        </Box>
        <Box sx={{display: 'flex', flexDirection: 'column', width: '300px'}}>
          <Box sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-end',
            marginTop: '2px',
            marginBottom: '2px'
          }}>
            {/*<Item backgroundColor="#006638">EM1: {em1}</Item>*/}
            <Typography variant="h7"
                        sx={{fontSize: '16px', marginLeft: '6px', marginRight: '6px'}}>EM1:{em1}</Typography>
            {/* operator sra show, operator pisa hidden */}
            {(appType === "PISA") && showAudit1 &&
              // <Item backgroundColor="#006638">AU1: {audit1}</Item>
              <Typography variant="h7"
                          sx={{fontSize: '16px', marginLeft: '6px', marginRight: '6px'}}>AU1:{audit1}</Typography>
            }
            {(appType === "SRA" || appType === "PRAP") &&
              // <Item backgroundColor="#006638">AU1: {audit1}</Item>
              <Typography variant="h7"
                          sx={{fontSize: '16px', marginLeft: '6px', marginRight: '6px'}}>AU1:{audit1}</Typography>
            }
            {/* operator pisa show */}
            {(appType === "PISA") &&
              // <Item backgroundColor="#006638">AUDIT: {audit2}</Item>
              <Typography variant="h7"
                          sx={{fontSize: '16px', marginLeft: '6px', marginRight: '6px'}}>AUDIT:{audit2}</Typography>
            }
            {/*<Item backgroundColor="#006638">EM2: {em2}</Item>*/}
            <Typography variant="h7"
                        sx={{fontSize: '16px', marginLeft: '6px', marginRight: '6px'}}>EM2:{em2}</Typography>
            {/*<Item backgroundColor="#006638">AUF: {audit3}</Item>*/}
            <Typography variant="h7"
                        sx={{fontSize: '16px', marginLeft: '6px', marginRight: '6px'}}>AUF:{audit3}</Typography>
            {/*<Item backgroundColor="#006638">Maks: {max}</Item>*/}
            <Typography variant="h7"
                        sx={{fontSize: '16px', marginLeft: '6px', marginRight: '6px'}}>Maksimum:{max}</Typography>
          </Box>
          {/*<Box sx={{display: 'flex', flexDirection: 'row', marginTop: '2px', marginBottom: '2px'}}>*/}
          {/*  <Item backgroundColor="#006638">Std: {sEm1}</Item>*/}
          {/*  {(appType === "PISA") && showAudit1 &&*/}
          {/*    <Item backgroundColor="#006638">Std: {sAudit1}</Item>*/}
          {/*  }*/}
          {/*  {appType === "SRA" &&*/}
          {/*    <Item backgroundColor="#006638">Std: {sAudit1}</Item>*/}
          {/*  }*/}
          {/*  {(appType === "PISA") &&*/}
          {/*    <Item backgroundColor="#006638">Std: {sAudit2}</Item>*/}
          {/*  }*/}
          {/*  <Item backgroundColor="#006638">Std: {sEm2}</Item>*/}
          {/*  <Item backgroundColor="#006638">Std: {sAudit3}</Item>*/}
          {/*  <Item backgroundColor="#006638">Std: {sMax}</Item>*/}
          {/*</Box>*/}
          {/*// TODO: for now hide, but next need to show*/}
          {/*<Box sx={{display: 'flex', flexDirection: 'row', marginTop: '2px', marginBottom: '2px'}}>*/}
          {/*  <Item backgroundColor="#006638">{qEm1}</Item>*/}
          {/*  <Item backgroundColor="#00594c">{qAudit1}</Item>*/}
          {/*  <Item backgroundColor="#004a59">{qAudit2}</Item>*/}
          {/*  <Item backgroundColor="#00395b">{qEm2}</Item>*/}
          {/*  <Item backgroundColor="#00274f">{qAudit3}</Item>*/}
          {/*  <Item backgroundColor="#161438">Total: {qTotal}</Item>*/}
          {/*</Box>*/}
        </Box>
        <Box>
          <Link to={link} style={{textDecoration: 'none'}}>
            <Button variant="contained" size="small"
                    sx={{
                      marginLeft: "8px", marginRight: "8px", textDecoration: 'none',
                      borderRadius: "0px", boxShadow: "none"
                    }}>Lihat</Button>
          </Link>
        </Box>
      </Box>
    </Paper>
  );
};

const Myitem = ({
                  no = "",
                  category = "",
                  em1 = "-",
                  audit1 = "-",
                  audit2 = "-",
                  em2 = "-",
                  audit3 = "-",
                  max = "-",
                  link,
                  sEm1 = "-",
                  sAudit1 = "-",
                  sAudit2 = "-",
                  sEm2 = "-",
                  sAudit3 = "-",
                  sMax = "-",
                  qEm1 = "-", qAudit1 = "-", qAudit2 = "-", qEm2 = "-", qAudit3 = "-", qTotal = "-",
                  showAudit1 = true,
                  appType = "SRA"
                }) => {
  return (
    <div>
      {(appType === "SRA" || appType === "LPKRA" || appType === "PRAP") &&
        <MyitemSRA no={no} category={category} em1={em1} audit1={audit1} audit2={audit2} em2={em2} audit3={audit3}
                   max={max} link={link} sEm1={sEm1} sAudit1={sAudit1} sAudit2={sAudit2} sEm2={sEm2}
                   sAudit3={sAudit3} sMax={sMax} qEm1={qEm1} qAudit1={qAudit1} qAudit2={qAudit2} qEm2={qEm2}
                   qAudit3={qAudit3} qTotal={qTotal} showAudit1={showAudit1}>
        </MyitemSRA>
      }
      {(appType === "PISA") &&
        <MyitemPISA no={no} category={category} em1={em1} audit1={audit1} audit2={audit2} em2={em2} audit3={audit3}
                    max={max} link={link} sEm1={sEm1} sAudit1={sAudit1} sAudit2={sAudit2} sEm2={sEm2}
                    sAudit3={sAudit3} sMax={sMax} qEm1={qEm1} qAudit1={qAudit1} qAudit2={qAudit2} qEm2={qEm2}
                    qAudit3={qAudit3} qTotal={qTotal} showAudit1={showAudit1}>
        </MyitemPISA>
      }
      {/*{(appType === "PRAP") &&*/}
      {/*  <MyitemPRAP no={no} category={category} em1={em1} audit1={audit1} audit2={audit2} em2={em2} audit3={audit3}*/}
      {/*              max={max} link={link} sEm1={sEm1} sAudit1={sAudit1} sAudit2={sAudit2} sEm2={sEm2}*/}
      {/*              sAudit3={sAudit3} sMax={sMax} qEm1={qEm1} qAudit1={qAudit1} qAudit2={qAudit2} qEm2={qEm2}*/}
      {/*              qAudit3={qAudit3} qTotal={qTotal} showAudit1={showAudit1}>*/}
      {/*  </MyitemPRAP>*/}
      {/*}*/}

    </div>
  );
};

const PRAP = () => {
  const {schoolid} = useParams();
  const history = useHistory();

  const [topCategories, setTopCategories] = useState(null);
  const [categories, setCategories] = useState(null);

  const appType = process.env.REACT_APP_APPTYPE;

  const [attachmentCount, setAttachmentCount] = useState(-1);  // jumlah attachment
  const [id, setId] = useState(schoolid); // id = em1id, em2id, audit1id, audit2id
  const [internalReadOnly, setInternalReadOnly] = useState(true);


  const {schoolInfo} = useSchoolInfo(schoolid);

  const {
    state: {userId, role, token},
  } = useUser();

  // get top categories
  // TODO: Add Top Categories. Using Axios
  useEffect(() => {
    if (process.env.REACT_APP_APPTYPE === "PRAP") {
      const urlSchoolTopCategories = process.env.REACT_APP_SERVER + "/schooltopcategories";
      axios.get(urlSchoolTopCategories, {params: {schoolid}})
        .then(response => {
          setTopCategories(response.data.data);
          console.log("topCategories", topCategories);
        })
        .catch(err => console.log(err));

      const urlSchoolCategories = process.env.REACT_APP_SERVER + "/schooltopcategoriesmulti";
      axios.get(urlSchoolCategories, {params: {schoolid}})
        .then(response => {
          setCategories(response.data);
          console.log("categories", categories);
        })
        .catch(err => console.log(err));

    }
  }, [schoolid]);

  useEffect(() => {
    if ((role == "auditor") || (role == "administrator")) {
      setInternalReadOnly(false);
    } else {
      setInternalReadOnly(true);
    }
  }, role);


  return (
    <div>
      {schoolInfo &&
        <div>
          <Grid container spacing={2}>
            <Grid item xs={8}>
            </Grid>
            <Grid item xs={4}>
              <SchoolWidget schoolInfo={schoolInfo}></SchoolWidget>
            </Grid>
          </Grid>
        </div>
      }
      <div className="categories">
        {(schoolInfo && (process.env.REACT_APP_DEV == 1)) &&
          <div>
            schoolid:{schoolid} - stage:{schoolInfo.stage} - userid:{userId} - role:{role}
          </div>
        }
        <p></p>
        <p></p>

        <Typography variant="h5">{`INSTRUMEN STANDARDISASI ${process.env.REACT_APP_TITLE.toUpperCase()}`}</Typography>
        <Typography variant="h6" gutterBottom={true}>INDIKATOR:</Typography>
        <Button variant="contained" sx={{borderRadius: "0px", boxShadow: "none"}}
                onClick={() => history.goBack()}>Kembali</Button>
        <p></p>
        <Stack direction="row" sx={{
          flexWrap: "wrap"
        }}>

          {/*<Accordion defaultExpanded={defaultExpanded}>*/}
          {/*  <AccordionSummary expandIcon={<ExpandMoreIcon/>}>{title}</AccordionSummary>*/}
          {/*  <AccordionDetails>*/}
          {/*    {children}*/}
          {/*  </AccordionDetails>*/}
          {/*</Accordion>*/}

          {topCategories && categories && schoolInfo &&
            topCategories.map((topCategory) => (
              <div key={topCategory.id}>
                <Accordion defaultExpanded={false}>
                  <AccordionSummary expandIcon={<ExpandMoreIcon/>}>
                    {/*{console.log(categories)}*/}
                    {/*<p>{topCategory.category_name}</p>*/}

                    <MyitemPRAP category={topCategory.category_name}
                                em1={topCategory.total_em_1_score} sEm1={topCategory.em_1_meet_standard}
                                audit1={topCategory.total_audit_1_score} sAudit1={topCategory.audit_1_meet_standard}
                                audit2={topCategory.total_audit_2_score} sAudit2={topCategory.audit_2_meet_standard}
                                em2={topCategory.total_em_2_score} sEm2={topCategory.em_2_meet_standard}
                                audit3={topCategory.total_audit_3_score} sAudit3={topCategory.audit_3_meet_standard}
                                max={topCategory.max_score} sMax={topCategory.standard_min}
                                link={`/schoolquestions2/${topCategory.id}/${schoolid}`}
                                showAudit1={true}
                                appType={appType}
                    ></MyitemPRAP>
                  </AccordionSummary>
                  <Divider></Divider>
                  {/*<div>*/}
                  <AccordionDetails>
                    {
                      categories.data.filter(category => category.top_category === topCategory.id).map((category) => (
                        <div key={category.id}>
                          <Myitem category={category.category_name}
                                  em1={category.total_em_1_score} sEm1={category.em_1_meet_standard}
                                  audit1={category.total_audit_1_score} sAudit1={category.audit_1_meet_standard}
                                  audit2={category.total_audit_2_score} sAudit2={category.audit_2_meet_standard}
                                  em2={category.total_em_2_score} sEm2={category.em_2_meet_standard}
                                  audit3={category.total_audit_3_score} sAudit3={category.audit_3_meet_standard}
                                  max={category.max_score} sMax={category.standard_min}
                                  link={`/schoolquestions2/${category.id}/${schoolid}`}
                                  showAudit1={true}
                                  appType={appType}
                          ></Myitem>
                        </div>
                      ))
                    }
                  </AccordionDetails>
                  {/*</div>*/}

                  {/*<p></p>*/}
                </Accordion>
                <p></p>
              </div>
            ))
          }

        </Stack>
        <Button variant="contained" sx={{borderRadius: "0px", boxShadow: "none"}}
                onClick={() => history.goBack()}>Kembali</Button>
      </div>

      <Attachment id={id} datasource="TOP" schoolid={schoolid}
                  readonly={internalReadOnly}
                  setAttachmentCount={setAttachmentCount}></Attachment>
      <div>
        <p></p>
        <p></p>

      </div>

    </div>
  );
};

const NonPRAP = () => {
  const {schoolid} = useParams();
  const history = useHistory();

  const appType = process.env.REACT_APP_APPTYPE;

  const [attachmentCount, setAttachmentCount] = useState(-1);  // jumlah attachment
  const [id, setId] = useState(schoolid); // id = em1id, em2id, audit1id, audit2id
  const [internalReadOnly, setInternalReadOnly] = useState(true);

  const {
    state: {userId, role, token},
  } = useUser();

  // fetch data for school-categories
  const {data, isPending, error} = useFetch(
    // check if process.env.REACT_APP_APPTYPE === "PRAP"
    process.env.REACT_APP_SERVER + "/schoolcategoriesmulti?schoolid=" + schoolid
  );
  // fetch data for schoolInfo (stage)
  const {schoolInfo} = useSchoolInfo(schoolid);

  useEffect(() => {
    if ((role == "auditor") || (role == "administrator")) {
      setInternalReadOnly(false);
    } else {
      setInternalReadOnly(true);
    }
  }, role);

  return (
    <div>
      {schoolInfo &&
        <div>
          <Grid container spacing={2}>
            <Grid item xs={8}>
            </Grid>
            <Grid item xs={4}>
              <SchoolWidget schoolInfo={schoolInfo}></SchoolWidget>
            </Grid>
          </Grid>
        </div>
      }
      <div className="categories">
        {(schoolInfo && (process.env.REACT_APP_DEV == 1)) &&
          <div>
            schoolid:{schoolid} - stage:{schoolInfo.stage} - userid:{userId} - role:{role}
          </div>
        }

        <p></p>
        <p></p>

        <Typography variant="h5">{`INSTRUMEN STANDARDISASI ${process.env.REACT_APP_TITLE.toUpperCase()}`}</Typography>
        <Typography variant="h6" gutterBottom={true}>INDIKATOR:</Typography>
        <Button variant="contained" sx={{borderRadius: "0px", boxShadow: "none"}}
                onClick={() => history.goBack()}>Kembali</Button>
        {error && <p className="error">{error}</p>}
        {isPending && <p className="loading">Loading...</p>}
        <Stack direction="row" sx={{
          flexWrap: "wrap"
        }}>

          {/* stage = 1,2,3, operator */}

          {data && schoolInfo && role == "operator" && (schoolInfo.stage === 1 || schoolInfo.stage === 2 || schoolInfo.stage === 3) &&
            data.data.map((question) => (
              <div key={question.id}>
                {/* role belum diteruskan */}

                <Myitem category={question.category_name}
                        em1={question.total_em_1_score} sEm1={question.em_1_meet_standard}
                        max={question.max_score} sMax={question.standard_min}
                        link={`/schoolquestions2/${question.id}/${schoolid}`}
                        showAudit1={false}
                        appType={appType}
                ></Myitem>
              </div>
            ))}

          {/* stage = 4,5, operator */}
          {data && schoolInfo && role == "operator" && (schoolInfo.stage === 4 || schoolInfo.stage === 5) &&
            data.data.map((question) => (
              <div key={question.id}>
                {/* role belum diteruskan */}
                <Myitem category={question.category_name}
                        em1={question.total_em_1_score} sEm1={question.em_1_meet_standard}
                        audit1={question.total_audit_1_score} sAudit1={question.audit_1_meet_standard}
                        audit2={question.total_audit_2_score} sAudit2={question.audit_2_meet_standard}
                        em2={question.total_em_2_score} sEm2={question.em_2_meet_standard}
                  // todo: check -> turn back to normal.
                  //       audit3={question.total_audit_3_score} sAudit3={question.audit_3_meet_standard}
                        max={question.max_score} sMax={question.standard_min}
                        link={`/schoolquestions2/${question.id}/${schoolid}`}
                        showAudit1={false}
                        appType={appType}
                ></Myitem>
              </div>
            ))}

          {/* stage = 6, operator */}
          {data && schoolInfo && role == "operator" && (schoolInfo.stage === 6) &&
            data.data.map((question) => (
              <div key={question.id}>
                {/* role belum diteruskan */}
                <Myitem category={question.category_name}
                        em1={question.total_em_1_score} sEm1={question.em_1_meet_standard}
                  // audit1={question.total_audit_1_score} sAudit1={question.audit_1_meet_standard}
                        audit2={question.total_audit_2_score} sAudit2={question.audit_2_meet_standard}
                        em2={question.total_em_2_score} sEm2={question.em_2_meet_standard}
                        audit3={question.total_audit_3_score} sAudit3={question.audit_3_meet_standard}
                        max={question.max_score} sMax={question.standard_min}
                        link={`/schoolquestions2/${question.id}/${schoolid}`}
                        showAudit1={false}
                        appType={appType}
                ></Myitem>
              </div>
            ))}

          {/* stage = 7, operator */}
          {/* stage for remedial */}
          {data && schoolInfo && role == "operator" && (schoolInfo.stage === 7) &&
            data.data.map((question) => (
              <div key={question.id}>
                {/* role belum diteruskan */}
                <Myitem category={question.category_name}
                        em1={question.total_em_1_score} sEm1={question.em_1_meet_standard}
                        audit1={question.total_audit_1_score} sAudit1={question.audit_1_meet_standard}
                        audit2={question.total_audit_2_score} sAudit2={question.audit_2_meet_standard}
                        em2={question.total_em_2_score} sEm2={question.em_2_meet_standard}
                  // todo: temporary show for repairing em2.
                        audit3={question.total_audit_3_score} sAudit3={question.audit_3_meet_standard}
                        max={question.max_score} sMax={question.standard_min}
                        link={`/schoolquestions2/${question.id}/${schoolid}`}
                        showAudit1={false}
                        appType={appType}
                ></Myitem>
              </div>
            ))}

          {/* all stage for auditor, viewerpusat, administrator */}
          {data && schoolInfo && (role == "auditor" || role == "viewerpusat" || role == "administrator") &&
            data.data.map((question) => (
              <div key={question.id}>
                {/* role belum diteruskan */}
                <Myitem category={question.category_name}
                        em1={question.total_em_1_score} sEm1={question.em_1_meet_standard}
                        audit1={question.total_audit_1_score} sAudit1={question.audit_1_meet_standard}
                        audit2={question.total_audit_2_score} sAudit2={question.audit_2_meet_standard}
                        em2={question.total_em_2_score} sEm2={question.em_2_meet_standard}
                        audit3={question.total_audit_3_score} sAudit3={question.audit_3_meet_standard}
                        max={question.max_score} sMax={question.standard_min}
                        link={`/schoolquestions2/${question.id}/${schoolid}`}
                        showAudit1={true}
                        appType={appType}
                ></Myitem>
              </div>
            ))}

        </Stack>
        <Button variant="contained" sx={{borderRadius: "0px", boxShadow: "none"}}
                onClick={() => history.goBack()}>Kembali</Button>
      </div>
      {(appType != "PISA") &&
        <Attachment id={id} datasource="TOP" schoolid={schoolid}
                    readonly={internalReadOnly}
                    setAttachmentCount={setAttachmentCount}></Attachment>
      }
      <div>
        <p></p>
        <p></p>

      </div>

    </div>

  );
};

export default function SchoolCategories2() {
  const {schoolid} = useParams();

  const appType = process.env.REACT_APP_APPTYPE;

  if (appType === "PRAP") {
    return (
      <PRAP></PRAP>
    );
  } else {
    return (
      <NonPRAP></NonPRAP>
    );
  }

}
