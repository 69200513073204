// styles
import "./Navbar.css";
import {Link} from "react-router-dom";

import {Typography, Grid, Button, Box} from "@mui/material";

const MenuItem = (props) => {
  return (
    <Box sx={{marginLeft: "20px", marginRight: "20px"}}>
      {props.children}
    </Box>
  );
};

export default function Navbar({role, signOut}) {
  return (
    <div className="container">
      <nav>
        <div className="logo">
          <Link to="/">
            {/*<img src="/logo.png" alt="image"/>*/}
            <img
              src="/logo.png"
              className="imglogo"
              alt="logo kpppa"
            />
          </Link>
        </div>
        <Box sx={{display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-end'}}>
          <MenuItem>
            {(role === "administrator") &&
              <Link to="/admin">
                <Typography variant="body1" className="textlink">Admin</Typography>
              </Link>
            }
          </MenuItem>
          {/*<MenuItem>*/}
          {/*  <Link to="/">*/}
          {/*    <h1 className="textlink">Data</h1>*/}
          {/*  </Link>*/}
          {/*</MenuItem>*/}
          <MenuItem>
            <Link to="/changepassword">
              <Typography variant="body1" className="textlink">Ganti Password</Typography>
            </Link>
          </MenuItem>
          <MenuItem>
            <Link to="/">
              <Typography variant="body1" className="textlink">Tutorial</Typography>
            </Link>
          </MenuItem>
          <MenuItem>
            <Link to="/">
              <Typography variant="body1" className="textlink">Kontak</Typography>
            </Link>
          </MenuItem>
          <MenuItem>
            <Link to="/">
              <Typography variant="body1" onClick={signOut} className="textlink">Logout</Typography>
              {/*<h1 className="textlink" onClick={signOut}>Logout</h1>*/}
            </Link>
          </MenuItem>
        </Box>
      </nav>
    </div>
  );
}
