import React, {useState, useEffect} from 'react';

import "./SchoolsManagement.css";
import axios from "axios";
import {Link, useHistory, useParams} from "react-router-dom";

import useUser from "../../user/use";

import {Box, Paper, Typography, Switch, Button, FormGroup, FormControlLabel} from "@mui/material";
import DialogY from "../../components/DialogY";

const baseURL = process.env.REACT_APP_SERVER;
const appType = process.env.REACT_APP_APPTYPE;

const listSchools = async (userId, role) => {
  try {
    const queryString = process.env.REACT_APP_SERVER + "/schools";
    const response = await axios.get(queryString, {params: {userId: userId, role: role}});
    // console.log("data:", data);
    // console.log(response.data.data);
    return response.data.data;
  } catch (err) {
    console.log(err);
  }
};

const realTransferData = (schoolId, stage) => {
  // if sra: transfer EM2 from Audit1
  // if pisa: transfer EM2 from EM1

  // console.log(stage, appType);

  let fromStage = "";
  let toStage = "";

  // console.log("stage:", stage);
  switch (stage) {
    case "EM1":
      // do nothing
      break;
    case "AUDIT1":
      fromStage = "EM1";
      toStage = "AUDIT1";
      break;
    case "AUDIT2":
      fromStage = "AUDIT1";
      toStage = "AUDIT2";
      break;
    case "EM2":
      toStage = "EM2";
      // todo: temporary for appType = pisa
      if ((appType === "SRA") || (appType === "LPKRA") || (appType === "PRAP") || (appType === "PISA")) {
        fromStage = "AUDIT1";
      } else if (appType === "PISA") {
        fromStage = "AUDIT2";
      }
      break;
    case "AUDIT3":
      fromStage = "EM2";
      toStage = "AUDIT3";
      break;
    default:
  }
  console.log(`real transfer data schoolId:${schoolId} effective from stage:${fromStage} to stage:${toStage} `);
  const sendData = {
    schoolId: schoolId,
    fromStage: fromStage,
    toStage: toStage
  };
  axios.post(baseURL + "/answer/transfer", sendData)
    .then(response => {
      console.log(response.data);
    }).catch(err => {
    console.log(err.message);
  });

  // call web service to transfer table stage
  return;
};

const realZeroingData = (schoolId, stage) => {

  const sendData = {
    schoolId: schoolId,
    stage: stage
  };
  axios.post(baseURL + "/answer/init", sendData)
    .then(response => {
      console.log(response.data);
    })
    .catch(err => {
      console.log(err.message);
    });

  console.log(`real zeroing data schoolId:${schoolId} effective stage:${stage}`);
  return;
};

const transferData = (schoolId = 0, stage = "", setOpen, setDialogDescription, setFunctionAction) => {
  const textShow = `transfer data schoolId:${schoolId} effective stage:${stage} from previous stage`;
  // if sra: transfer EM2 from Audit1
  // if pisa: transfer EM2 from EM1

  // console.log(textShow);
  setDialogDescription(textShow);
  setFunctionAction(() => () => {
    realTransferData(schoolId, stage);
    // console.log("harusnya ini ngga keluar dulu");
  });
  setOpen(true);
  return;
};

const zeroingData = (schoolId = 0, stage = "", setOpen, setDialogDescription, setFunctionAction) => {
  const textShow = `zeroing data schoolId:${schoolId} effective stage:${stage}`;
  // console.log(textShow);
  setDialogDescription(textShow);
  setFunctionAction(() => () => {
    realZeroingData(schoolId, stage);
    // console.log("harusnya ini ngga keluar dulu");
  });
  setOpen(true);
  return;
};

const ActionButton = ({children}) => {
  return (
    <Button variant="contained" size="small" sx={{margin: "5px", fontSize: "10px"}}>
      {children}
    </Button>
  );
};

const TransferButton = ({
                          children,
                          setOpen,
                          setDialogDescription,
                          setFunctionAction,
                          schoolId,
                          stage = "",
                          disabled = false
                        }) => {
  return (
    <Button onClick={() => {
      transferData(schoolId, stage, setOpen, setDialogDescription, setFunctionAction);
    }} variant="contained" size="small" disabled={disabled}
            sx={{margin: "5px", fontSize: "10px"}}>
      {children}
    </Button>
  );
};

const ZeroingButton = ({
                         children,
                         setOpen,
                         setDialogDescription,
                         setFunctionAction,
                         schoolId,
                         stage = "",
                         disabled = false
                       }) => {
  return (
    <Button onClick={() => {
      zeroingData(schoolId, stage, setOpen, setDialogDescription, setFunctionAction);
    }} variant="contained" size="small" sx={{margin: "5px", fontSize: "10px"}}>
      {children}
    </Button>
  );
};

const ActionColumn = ({setOpen, setDialogDescription, setFunctionAction, schoolID, stage, disabled = false}) => {
  return (
    <Box sx={{display: "flex", flexDirection: 'column', width: '100px', textAlign: 'center'}}>
      <Box sx={{fontSize: '12px', height: '25px'}}>
        <Typography fontSize={12}>{stage}</Typography>
      </Box>
      <Box>
        <TransferButton setOpen={setOpen} setDialogDescription={setDialogDescription}
                        setFunctionAction={setFunctionAction}
                        schoolId={schoolID} stage={stage}
                        disabled={disabled}>Transfer</TransferButton>
      </Box>
      <Box>
        <ZeroingButton setOpen={setOpen} setDialogDescription={setDialogDescription}
                       setFunctionAction={setFunctionAction}
                       schoolId={schoolID} stage={stage}
                       disabled={disabled}>Zeroing</ZeroingButton>
      </Box>
    </Box>
  );
};

const SchoolView = ({setOpen, setDialogDescription, setFunctionAction, id, schoolName, stage}) => {
  // TODO: turn on off (active or not active) --> saving
  const [active, setActive] = useState(false);
  const handleChange = () => {
    setActive(!active);
  };

  return (
    <div>
      <Paper elevation={3} sx={{margin: "10px"}}>
        <Box sx={{display: 'flex', flexDirection: 'row'}}>
          <Box sx={{width: '50px', margin: 'auto', textAlign: 'center'}}>
            {id}
          </Box>
          <Box sx={{width: '400px'}}>
            <Typography>{schoolName}</Typography>
            <Box><ActionButton>Get Detail</ActionButton></Box>
            <Box><ActionButton>Change Info</ActionButton></Box>
            <Box>
              <FormGroup>
                <FormControlLabel control={
                  <Switch size="small" checked={active}
                          onChange={handleChange}></Switch>
                } label="active"/>
              </FormGroup>
            </Box>
          </Box>
          <Box sx={{width: '120px', textAlign: 'center'}}>
            <Box sx={{height: '24px'}}><Typography fontSize={12}>Stage:{stage}</Typography></Box>
            <Box><ActionButton>Change Stage</ActionButton></Box>
          </Box>
          <Box sx={{display: 'flex', flexDirection: 'row', width: '800px'}}>
            <ActionColumn setOpen={setOpen} setDialogDescription={setDialogDescription}
                          setFunctionAction={setFunctionAction}
                          schoolID={id} stage={"EM1"}
                          disabled={true}></ActionColumn>
            <ActionColumn setOpen={setOpen} setDialogDescription={setDialogDescription}
                          setFunctionAction={setFunctionAction}
                          schoolID={id}
                          stage={"AUDIT1"}></ActionColumn>
            <ActionColumn setOpen={setOpen} setDialogDescription={setDialogDescription}
                          setFunctionAction={setFunctionAction}
                          schoolID={id}
                          stage={"AUDIT2"}></ActionColumn>
            <ActionColumn setOpen={setOpen} setDialogDescription={setDialogDescription}
                          setFunctionAction={setFunctionAction}
                          schoolID={id}
                          stage={'EM2'}></ActionColumn>
            <ActionColumn setOpen={setOpen} setDialogDescription={setDialogDescription}
                          setFunctionAction={setFunctionAction}
                          schoolID={id}
                          stage={'AUDIT3'}></ActionColumn>
          </Box>
          <Box>
            <ActionButton>User Access</ActionButton>
          </Box>
          <Box>
            <ActionButton>History</ActionButton>
          </Box>
          <Box>
            <Link to={`/schoolcategories2/${id}`}>
              <ActionButton>Go to School</ActionButton>
            </Link>
          </Box>
        </Box>
      </Paper>
    </div>
  );
};

function SchoolsManagement(props) {
  const [dataSchools, setDataSchools] = useState(null);

  const [open, setOpen] = useState(false);
  const [dialogDescription, setDialogDescription] = useState("");
  const [dialogTitle, setDialogTitle] = useState("Proses");
  const [linkAction, setLinkAction] = useState(false);

  const [functionAction, setFunctionAction] = useState(null);

  const [schoolId, setSchoolId] = useState(0);
  const [tableStage, setTableStage] = useState(null);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };


  useEffect(() => {
    if (linkAction && functionAction) {
      // console.log("running beneran nih");
      setOpen(false);
      functionAction();
      setFunctionAction(null);
      setLinkAction(false);
    }
  }, [functionAction, linkAction]);

  const {
    state: {userId, role, token},
  } = useUser();

  useEffect(() => {
    listSchools(userId, role)
      .then(
        response => setDataSchools(response)
      )
      .catch(err => {
        console.log(err);
      });
    // if (data) {
    // }
  }, [role, userId]);

  return (
    <div className="schoolsmanagement">
      {/*show all schools */}
      <ActionButton>Create New School</ActionButton>
      {/*<SchoolView setOpen={setOpen} setDialogDescription={setDialogDescription} setFunctionAction={setFunctionAction}>*/}
      {/*</SchoolView>*/}
      {dataSchools &&
        <div>
          {dataSchools.map(dataSchool => {
            return (
              <div key={dataSchool.id}>
                {/*{dataSchool.school_name}*/}
                <SchoolView setOpen={setOpen} setDialogDescription={setDialogDescription}
                            setFunctionAction={setFunctionAction} id={dataSchool.id}
                            schoolName={dataSchool.school_name} stage={dataSchool.stage}></SchoolView>
              </div>
            );
          })
          }
        </div>
      }
      <DialogY open={open} onAction={() => {
        // console.log("ready start finish");
        setLinkAction(true);
      }} onClose={handleClose} description={dialogDescription} title={dialogTitle}/>
    </div>
  );
}

export default SchoolsManagement;