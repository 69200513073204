import * as React from "react";
import {useState} from "react";

import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import {FormControl, IconButton, InputAdornment, InputLabel, MenuItem, Select} from "@mui/material";
import Link from "@mui/material/Link";
import Box from "@mui/material/Box";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import Paper from "@mui/material/Paper";
import {createTheme, ThemeProvider} from "@mui/material/styles";

// import PropTypes from "prop-types";
import useUser from "../../user/use";
import axios from "axios";
import qs from 'qs';

import DialogX from "../../components/DialogX";
import {Visibility, VisibilityOff} from "@mui/icons-material";

// const FormData = require('form-data');

import candles from './pisa-back-1.jpeg';

const backgroundImage = 'https://i0.wp.com/brainlessbrain.com/wp-content/uploads/2022/02/pexels-photo-794644.jpeg?w=1880&ssl=1';

const styles = {
  container: {
    backgroundImage: `url("https://i0.wp.com/brainlessbrain.com/wp-content/uploads/2022/02/pexels-photo-794644.jpeg?w=1880&ssl=1")`,
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    width: '100vw',
    height: '100vh'
  }
};

// import backgroundSRA from "../../img/design-login-SRA-2022.jpg";

function Copyright(props) {
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      {...props}
    >
      {" "}
      <Link color="inherit" href="#">
        {process.env.REACT_APP_TITLE} - KemenPPPA
      </Link>
      {" - "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

const theme = createTheme();

// https://stackoverflow.com/questions/60391113/how-to-view-password-from-material-ui-textfield

export default function SignIn() {
  // const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  // const [role, setRole] = React.useState("");
  const [open, setOpen] = useState(false);
  const [dialogDescription, setDialogDescription] = useState("");
  const [dialogTitle, setDialogTitle] = useState("Sign in");

  const [email, setEmail] = useState("");

  const appType = process.env.REACT_APP_APPTYPE;

  const {
    state: {userId, schoolId, role, token},
    actions: {setUserId, setSchoolId, setRole, setToken}
  } = useUser();

  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleMouseDownPassword = () => setShowPassword(!showPassword);

  // for debugging
  // setEmail("auditor1@kla.id");
  // setPassword("audit2022")
  // setRole("auditor");


  // const handleChange = (event) => {
  //   setRole(event.target.value);
  // };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (role === "" || email === "" || password === "") {
      // console.log("Ada isian yang kosong");
      setDialogDescription("Email, Password, dan Hak Akses harus diisi semua.");
      setOpen(true);
      return;
    }

    const data = {
      email: email,
      password: password
    };
    // console.log("data", data);
    const url = process.env.REACT_APP_SERVER + "/login";
    const options = {
      method: 'POST',
      headers: {'content-type': 'application/x-www-form-urlencoded'},
      // data: formData,
      data: qs.stringify(data),
      url,
    };

    axios(options)
      .then(response => {
        if ((response.data.message === "User not Found") || (response.data.message === "Wrong Password")) {
          // TODO: add popup windows
          // console.log("User/Pass Wrong");
          setDialogDescription("Email tidak terdaftar, atau salah Password.");
          setOpen(true);
        } else if (response.data.message === "User not Active") {
          // console.log("User not Active");
          setDialogDescription("Email dan Password tidak aktif, tidak bisa digunakan.");
          setOpen(true);
        } else {
          let tempToken = response.data.token;
          let tempUserId = response.data.id;
          // check role.
          const query2 = process.env.REACT_APP_SERVER + "/anyrole";
          axios.get(query2, {params: {secret_token: response.data.token, role: role}})
            .then(response => {
                // console.log("response", response.data);
                // console.log("response.data.message", response.data.message);
                if (response.data.message === "Don't Have Access") {
                  // console.log("User/Pass Wrong");
                  setDialogDescription(`Anda tidak mempunyai akses ${role}`);
                  setOpen(true);
                  setToken("");
                } else {
                  setToken({token: tempToken});
                  setUserId(tempUserId); // get userId from response
                }
              }
            )
            .catch(err => {
              console.log(err);
            });
        }
        // console.log(token);
      })
      .catch(err => {
        console.log(err.response.data);
      });
  };

  const localStyle = (appType === "PISA") ? {backgroundImage: `url(${candles})`, backgroundRepeat: 'no-repeat', backgroundSize: 'cover'
    , width: '100vw'
    , height: '100vh'} : {};

  return (
    // <div className={`${styles.container}`}>

    <Box
      class="candles"
      style={ localStyle }>
          {/*<div className="signin">*/}
          <ThemeProvider theme={theme}>
            <div style={{paddingTop: '100px'}}>
              {/*<div id="bg" style={{*/}
              {/*  backgroundImage: `url(${backgroundSRA})`, minHeight: "100vh", backgroundSize: "cover"*/}
              {/*}}>*/}
              {/*<img src="../../img/design-login-SRA-2022.jpg" id="bg" alt=""></img>*/}
              {/*<div id="bg">*/}
              {/*  <img src="../../img/design-login-SRA-2022.jpg" alt=""></img>*/}
              {/*</div>*/}
              {/*<p style={{"backgroundImage": "url('/design-login-SRA-2022.jpg')"}}/>*/}
              <Box sx={{
                margin: 'auto auto', paddingTop: '2px', paddingBottom: '10px',
                backgroundColor: 'white', width: '480px'
              }}>
                <Container component="main" maxWidth="xs">
                  {/*<form onSubmit={handleSubmit}>*/}
                  <CssBaseline/>
                  <Box
                    sx={{
                      marginTop: 8,
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                    }}
                  >
                    <Avatar sx={{m: 1, bgcolor: "secondary.main"}}>
                      <img src="./76.png" alt="logo"/>
                      {/*<LockOutlinedIcon/>*/}
                    </Avatar>
                    <Typography component="h1" variant="h5">
                      Sign in
                    </Typography>
                    <Box
                      component="form"
                      onSubmit={handleSubmit}
                      noValidate
                      sx={{mt: 1}}
                    >
                      <Box sx={{minWidth: 120, mt: 5}}>
                        <FormControl fullWidth>
                          <InputLabel id="hak-akses-select-label">Hak Akses</InputLabel>
                          <Select
                            labelId="hak-akses-select-label"
                            id="hakakses"
                            value={role}
                            label="Hak Akses"
                            onChange={(e) => setRole(e.target.value)}
                          >
                            <MenuItem value={"operator"}>Peserta</MenuItem>
                            <MenuItem value={"auditor"}>Auditor</MenuItem>
                            <MenuItem value={"viewerpusat"}>Viewer Pusat</MenuItem>
                            <MenuItem value={"administrator"}>Administrator</MenuItem>
                            {/*<MenuItem value={"pusat"}>Pusat</MenuItem>*/}
                          </Select>
                        </FormControl>
                      </Box>
                      <TextField
                        margin="normal"
                        required
                        fullWidth
                        id="email"
                        label="Email Address"
                        name="email"
                        autoComplete="email"
                        autoFocus
                        onChange={(e) => setEmail(e.target.value)}
                      />
                      <TextField
                        margin="normal"
                        required
                        fullWidth
                        name="password"
                        label="Password"
                        type={showPassword ? "text" : "password"}
                        // type="password"
                        id="password"
                        autoComplete="current-password"
                        onChange={(e) => setPassword(e.target.value)}
                        InputProps={{ // <-- This is where the toggle button is added.
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                aria-label="toggle password visibility"
                                onClick={handleClickShowPassword}
                                onMouseDown={handleMouseDownPassword}
                              >
                                {showPassword ? <Visibility/> : <VisibilityOff/>}
                              </IconButton>
                            </InputAdornment>
                          )
                        }}
                      />
                      <FormControlLabel
                        control={<Checkbox value="remember" color="primary"/>}
                        label="Remember me"
                      />
                      <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        sx={{mt: 3, mb: 2}}
                        // onClick={handleSubmit}
                      >
                        Sign In
                      </Button>

                      {/*<Grid container>*/}
                      {/*  <Grid item xs>*/}
                      {/*    <Link href="sra3frontend/src/pages/Signup/SignIn#" variant="body2">*/}
                      {/*      Forgot password?*/}
                      {/*    </Link>*/}
                      {/*  </Grid>*/}
                      {/*  <Grid item>*/}
                      {/*    <Link href="sra3frontend/src/pages/Signup/SignIn#" variant="body2">*/}
                      {/*      {"Don't have an account? Sign Up"}*/}
                      {/*    </Link>*/}
                      {/*  </Grid>*/}
                      {/*</Grid>*/}

                    </Box>
                  </Box>
                  <Copyright sx={{mt: 8, mb: 4}}/>
                  {/*</form>*/}
                </Container>
              </Box>
              <DialogX open={open} onClose={handleClose} description={dialogDescription} title={dialogTitle}/>

            </div>

          </ThemeProvider>

        </Box>

        );
      }

      // validate first: right email? right password (no space)
      // when submitting, check first email + password
      // after that check role, have access as role. if yes token + useState
      // https://mui.com/material-ui/react-select/
      // https://codesandbox.io/s/e2sp3s?file=/demo.js:304-421

      // SignIn.propTypes = {
//   setToken: PropTypes.func.isRequired,
// };