import React, {useEffect, useState} from 'react';
import useUser from "../../user/use";
import axios from "axios";

import {Link, useHistory, useParams} from "react-router-dom";

import "./Admin.css";

function Admin() {
  return (
    <div className="admin">
      <div>
        <p>Admin</p>
        <Link to={'/usersmanagement'}>
          <p>Users Management</p>
        </Link>
        <Link to={'/schoolsmanagement'}>
          <p>Schools Management</p>
        </Link>
        <p>stage</p>
      </div>
    </div>);
}

export default Admin;