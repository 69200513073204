import React from 'react';

import "./UsersManagement.css";
import axios from "axios";
import {useEffect, useState} from "react";
import useUser from "../../user/use";

const listSchools = async (userId, role) => {
  try {
    const queryString = process.env.REACT_APP_SERVER + "/schools";
    const response = await axios.get(queryString, {params: {userId: userId, role: role}});
    // console.log("data:", data);
    // console.log(response.data.data);
    return response.data.data;
  } catch (err) {
    console.log(err);
  }
};

function UsersManagement(props) {
  const [dataUsers, setDataUsers] = useState(null);

  const {
    state: {userId, role, token},
  } = useUser();

  useEffect(() => {
    listSchools(userId, role)
      .then(
        response => setDataUsers(response)
      )
      .catch(err => {
        console.log(err);
      });
    // if (data) {
    // }
  }, [role, userId]);

  return (
    <div className="schoolsmanagement">
      show all users
      {dataUsers &&
        <div>
          {console.log("masuklah")}
          {console.log(dataUsers)}
          {console.log(dataUsers[0].school_name)}
          {/*{dataSchools.map(dataSchool => {*/}
          {/*  return (*/}
          {/*    <div>*/}
          {/*      {dataSchools.school_name}*/}
          {/*    </div>*/}
          {/*  );*/}
          {/*})*/}
          {/*})*/}
        </div>
      }
    </div>
  );
}

export default UsersManagement;
