import React, {useState} from "react";
import axios from "axios";
import {Box, Button, TextField, Typography} from "@mui/material";
import DialogX from "./DialogX";
import * as PropTypes from "prop-types";


export default function UploadComponent(props) {
  const [image, setImage] = useState({preview: "", data: ""});
  const [status, setStatus] = useState("");
  const [description, setDescription] = useState("");

  // Untuk Dialog Alert
  const [open, setOpen] = useState(false);
  const [dialogDescription, setDialogDescription] = useState("");
  const [dialogTitle, setDialogTitle] = useState("Attachment");
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const handleFileChange = (e) => {
    const img = {
      preview: URL.createObjectURL(e.target.files[0]),
      data: e.target.files[0],
    };
    setImage(img);
  };

  const handleSubmit = (e) => {
    // e.preventDefault();
    // check both field should not empty.
    if (description === "") {
      console.log("File Description may not empty");
      setDialogDescription("Keterangan file tidak boleh kosong.");
      setOpen(true);
      return;
    }
    if (image.data === "") {
      console.log("File should exist");
      setDialogDescription("File harus dipilih.");
      setOpen(true);
      return;
    }
    if (image.data && Object.keys(image.data).length === 0 && Object.getPrototypeOf(image.data) === Object.prototype) {
      console.log("harus ada file baru upload");  // kurang paham ini buat apa.
      setDialogDescription("Harus ada file.");
      setOpen(true);
      return;
    }

    let formData = new FormData();
    // formData.append("userid", userId);
    // formData.append("role", role);
    formData.append("schoolid", props.schoolid);
    formData.append("datasource", props.datasource);
    formData.append("id", props.id);
    formData.append("description", description);
    formData.append("image", image.data);

    // console.log("formData:", formData)
    const path = process.env.REACT_APP_SERVER + "/attachment/upload";
    console.log("path:", path);
    axios.post(process.env.REACT_APP_SERVER + "/attachment/upload", formData)
      .then(response => {
        props.setFileCount(props.fileCount + 2);
        if (response) {
          setStatus(response.statusText);
          // cleaning form input
        }
      }).catch(err => {
      console.log(err);
    });
  };

  return (
    <div>
      {!(props.readonly) && (
        <div>
          <Typography variant="h6">
            Unggah ke server
          </Typography>
          {/*<hr></hr>*/}
          {/*<p></p>*/}
          <Box sx={{
            width: "500px",
            border: 1,
            borderColor: props.borderColor,
            padding: 2
          }}>
            <Box sx={{width: "500px",}}>
              <TextField type="file" name="file2" onChange={handleFileChange} sx={{width: "500px",}}>File</TextField>
            </Box>
            <p></p>
            <TextField type="text" name="description2" label="Keterangan"
                       onChange={(e) => setDescription(e.target.value)}
                       sx={{width: "500px",}}></TextField>
            {/*<Box sx={{marginTop: 5}}>*/}
            <Button variant="contained" color="success" onClick={handleSubmit} sx={{marginTop: 2}}>Unggah</Button>
            {/*</Box>*/}
            {/*{status && <h5>{status}</h5>}*/}
          </Box>
        </div>
      )
      }
      <DialogX open={open} onClose={handleClose} description={dialogDescription} title={dialogTitle}/>
    </div>);
}

UploadComponent.propTypes = {
  borderColor: PropTypes.func,
  id: PropTypes.number,
  datasource: PropTypes.string,
  fileCount: PropTypes.number,
  setFileCount: PropTypes.func,
  schoolid: PropTypes.string,
};