import React, {useEffect, useState} from 'react';
import axios from "axios";

import {Paper, Box, Button, Typography} from "@mui/material";

import "./SchoolsList.css";
import {Link, useHistory, useParams} from "react-router-dom";

import useUser from "../../user/use";

const Item = (props) => {
  return (
    <Box sx={{
      width: '50px',
      backgroundColor: `${props.backgroundColor}`,
      color: "white",
      marginLeft: "2px",
      marginRight: "2px",
      // margin: "auto",
      textAlign: "center",
      fontSize: "10px",
      paddingTop: "4px",
      paddingRight: "2px",
      paddingBottom: "4px",
      paddingLeft: "2px",
    }}>
      {props.children}
    </Box>
  );
};

const MyitemSRA = ({
                     no = "",
                     schoolName = "",
                     regencyName = "",
                     provinceName = "",
                     em1 = "-",
                     audit1 = "-",
                     audit2 = "-",
                     em2 = "-",
                     audit3 = "-",
                     max = "-",
                     link,
                     sEm1 = "-",
                     sAudit1 = "-",
                     sAudit2 = "-",
                     sEm2 = "-",
                     sAudit3 = "-",
                     sMax = "-",
                     qEm1 = "-", qAudit1 = "-", qAudit2 = "-", qEm2 = "-", qAudit3 = "-", qTotal = "-",
                     showAudit1 = true,
                     appType = "SRA"
                   }) => {
  String.prototype.toProperCase = function () {
    return this.replace(/\w\S*/g, function (txt) {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
  };
  return (
    <Paper elevation={0}
           sx={{padding: "10px", marginLeft: "15px", marginRight: "15px", marginTop: "10px", marginBottom: "10px"}}>
      <Box sx={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
        <Box sx={{width: '60px', textAlign: 'center', marginLeft: '4px', marginRight: '4px'}}>
          {no}
        </Box>
        <Box sx={{width: "680px", paddingLeft: "5px", paddingRight: "5px", fontSize: "20px"}}>
          {schoolName}
        </Box>
        <Box sx={{width: "680px", paddingLeft: "5px", paddingRight: "5px", fontSize: "16px"}}>
          {provinceName.toProperCase()}
        </Box>
        <Box sx={{width: "680px", paddingLeft: "5px", paddingRight: "5px", fontSize: "16px"}}>
          {regencyName.toProperCase()}
        </Box>
        <Box sx={{display: 'flex', flexDirection: 'column'}}>
          <Box sx={{display: 'flex', flexDirection: 'row', marginTop: '2px', marginBottom: '2px'}}>
            <Item backgroundColor="#006638">EM1: {em1}</Item>
            {showAudit1 && (appType === "SRA" || appType === "PISA" || appType === "PRAP") &&
              <Item backgroundColor="#006638">AU1: {audit1}</Item>
            }
            {(appType === "PISA") &&
              <Item backgroundColor="#006638">AUDIT: {audit2}</Item>
            }
            <Item backgroundColor="#006638">EM2: {em2}</Item>
            <Item backgroundColor="#006638">AUF: {audit3}</Item>
            <Item backgroundColor="#006638">Maks: {max}</Item>
          </Box>
          <Box sx={{display: 'flex', flexDirection: 'row', marginTop: '2px', marginBottom: '2px'}}>
            <Item backgroundColor="#006638">Std: {sEm1}</Item>
            {showAudit1 && (appType === "SRA" || appType === "PISA" || appType === "PRAP") &&
              <Item backgroundColor="#006638">Std: {sAudit1}</Item>
            }
            {(appType === "PISA") &&
              <Item backgroundColor="#006638">Std: {sAudit2}</Item>
            }
            <Item backgroundColor="#006638">Std: {sEm2}</Item>
            <Item backgroundColor="#006638">Std: {sAudit3}</Item>
            <Item backgroundColor="#006638">Std: {sMax}</Item>
          </Box>
          {/*// TODO: for now hide, but next need to show*/}
          {/*<Box sx={{display: 'flex', flexDirection: 'row', marginTop: '2px', marginBottom: '2px'}}>*/}
          {/*  <Item backgroundColor="#006638">{qEm1}</Item>*/}
          {/*  <Item backgroundColor="#00594c">{qAudit1}</Item>*/}
          {/*  <Item backgroundColor="#004a59">{qAudit2}</Item>*/}
          {/*  <Item backgroundColor="#00395b">{qEm2}</Item>*/}
          {/*  <Item backgroundColor="#00274f">{qAudit3}</Item>*/}
          {/*  <Item backgroundColor="#161438">Total: {qTotal}</Item>*/}
          {/*</Box>*/}
        </Box>
        <Box>
          <Link to={link} style={{textDecoration: 'none'}}>
            <Button variant="contained" size="small"
                    sx={{
                      marginLeft: "8px", marginRight: "8px", textDecoration: 'none',
                      borderRadius: "0px", boxShadow: "none"
                    }}>Lihat</Button>
          </Link>
        </Box>
      </Box>
    </Paper>
  );
};

const MyitemPISA = ({
                      no = "",
                      schoolName = "",
                      regencyName = "",
                      provinceName = "",
                      em1 = "-",
                      audit1 = "-",
                      audit2 = "-",
                      em2 = "-",
                      audit3 = "-",
                      max = "-",
                      link,
                      sEm1 = "-",
                      sAudit1 = "-",
                      sAudit2 = "-",
                      sEm2 = "-",
                      sAudit3 = "-",
                      sMax = "-",
                      qEm1 = "-", qAudit1 = "-", qAudit2 = "-", qEm2 = "-", qAudit3 = "-", qTotal = "-",
                      showAudit1 = true,
                      appType = "SRA",
                      stage = 0,
                      role = ""
                    }) => {
  String.prototype.toProperCase = function () {
    return this.replace(/\w\S*/g, function (txt) {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
  };
  return (
    <Paper elevation={0}
           sx={{padding: "10px", marginLeft: "15px", marginRight: "15px", marginTop: "10px", marginBottom: "10px"}}>
      <Box sx={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
        {/*<Box sx={{width: '60px', textAlign: 'center', marginLeft: '4px', marginRight: '4px'}}>*/}
        {no}
        {/*</Box>*/}
        <Box sx={{width: "680px", paddingLeft: "5px", paddingRight: "5px", fontSize: "20px"}}>
          {schoolName}
        </Box>
        <Box sx={{width: "680px", paddingLeft: "5px", paddingRight: "5px", fontSize: "16px"}}>
          {provinceName.toProperCase()}
        </Box>
        <Box sx={{width: "680px", paddingLeft: "5px", paddingRight: "5px", fontSize: "16px"}}>
          {regencyName.toProperCase()}
        </Box>
        <Box sx={{display: 'flex', flexDirection: 'column'}}>
          <Box sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-end',
            marginTop: '2px',
            marginBottom: '2px',
            width: '350px'
          }}>
            {/*<Item backgroundColor="#006638">EM1: {em1}</Item>*/}
            <Typography variant="h7"
                        sx={{fontSize: '16px', marginLeft: '6px', marginRight: '6px'}}>EM1:{em1}</Typography>
            {showAudit1 && (appType === "SRA" || appType === "PISA" || appType === "PRAP") &&
              <Typography variant="h7"
                          sx={{fontSize: '16px', marginLeft: '6px', marginRight: '6px'}}>AU1:{audit1}</Typography>
            }
            {(appType === "PISA") &&
              <Typography variant="h7"
                          sx={{fontSize: '16px', marginLeft: '6px', marginRight: '6px'}}>AUDIT:{audit2}</Typography>
            }
            {/*<Item backgroundColor="#006638">EM2: {em2}</Item>*/}
            <Typography variant="h7"
                        sx={{fontSize: '16px', marginLeft: '6px', marginRight: '6px'}}>EM2:{em2}</Typography>
            {/*<Item backgroundColor="#006638">AUF: {audit3}</Item>*/}
            {
              ((stage !== 6) && (role == "operator")) &&
              <Typography variant="h7"
                          sx={{fontSize: '16px', marginLeft: '6px', marginRight: '6px'}}>AUF:-</Typography>
            }
            {
              ((stage === 6) && (role == "operator")) &&
              <Typography variant="h7"
                          sx={{fontSize: '16px', marginLeft: '6px', marginRight: '6px'}}>AUF: {audit3}</Typography>
            }
            {
              ((role == "auditor") || (role == "administrator")) &&
              <Typography variant="h7"
                          sx={{fontSize: '16px', marginLeft: '6px', marginRight: '6px'}}>AUF: {audit3}</Typography>
            }

            {/*<Item backgroundColor="#006638">Maks: {max}</Item>*/}
            <Typography variant="h7"
                        sx={{fontSize: '16px', marginLeft: '6px', marginRight: '6px'}}>Maks:{max}</Typography>
          </Box>
          {/*<Box sx={{display: 'flex', flexDirection: 'row', marginTop: '2px', marginBottom: '2px'}}>*/}
          {/*  <Item backgroundColor="#006638">Std: {sEm1}</Item>*/}
          {/*  {showAudit1 && (appType === "SRA" || appType === "PISA") &&*/}
          {/*    <Item backgroundColor="#006638">Std: {sAudit1}</Item>*/}
          {/*  }*/}
          {/*  {(appType === "PISA") &&*/}
          {/*    <Item backgroundColor="#006638">Std: {sAudit2}</Item>*/}
          {/*  }*/}
          {/*  <Item backgroundColor="#006638">Std: {sEm2}</Item>*/}
          {/*  <Item backgroundColor="#006638">Std: {sAudit3}</Item>*/}
          {/*  <Item backgroundColor="#006638">Std: {sMax}</Item>*/}
          {/*</Box>*/}
          {/*// TODO: for now hide, but next need to show*/}
          {/*<Box sx={{display: 'flex', flexDirection: 'row', marginTop: '2px', marginBottom: '2px'}}>*/}
          {/*  <Item backgroundColor="#006638">{qEm1}</Item>*/}
          {/*  <Item backgroundColor="#00594c">{qAudit1}</Item>*/}
          {/*  <Item backgroundColor="#004a59">{qAudit2}</Item>*/}
          {/*  <Item backgroundColor="#00395b">{qEm2}</Item>*/}
          {/*  <Item backgroundColor="#00274f">{qAudit3}</Item>*/}
          {/*  <Item backgroundColor="#161438">Total: {qTotal}</Item>*/}
          {/*</Box>*/}
        </Box>
        <Box>
          <Link to={link} style={{textDecoration: 'none'}}>
            <Button variant="contained" size="small"
                    sx={{
                      marginLeft: "8px", marginRight: "8px", textDecoration: 'none',
                      borderRadius: "0px", boxShadow: "none"
                    }}>Lihat</Button>
          </Link>
        </Box>
      </Box>
    </Paper>
  );
};

const Myitem = ({
                  no = "",
                  schoolName = "",
                  regencyName = "",
                  provinceName = "",
                  em1 = "-",
                  audit1 = "-",
                  audit2 = "-",
                  em2 = "-",
                  audit3 = "-",
                  max = "-",
                  link,
                  sEm1 = "-",
                  sAudit1 = "-",
                  sAudit2 = "-",
                  sEm2 = "-",
                  sAudit3 = "-",
                  sMax = "-",
                  qEm1 = "-", qAudit1 = "-", qAudit2 = "-", qEm2 = "-", qAudit3 = "-", qTotal = "-",
                  showAudit1 = true,
                  appType = "SRA",
                  stage = 0,
                  role = ""
                }) => {
  return (
    <div>
      {(appType === "SRA" || appType === "LPKRA" || appType === "PRAP") &&
        <MyitemSRA no={no} schoolName={schoolName} regencyName={regencyName} provinceName={provinceName}
                   em1={em1} audit1={audit1} audit2={audit2} em2={em2} audit3={audit3}
                   max={max} link={link} sEm1={sEm1} sAudit1={sAudit1} sAudit2={sAudit2} sEm2={sEm2}
                   sAudit3={sAudit3} sMax={sMax} qEm1={qEm1} qAudit1={qAudit1} qAudit2={qAudit2} qEm2={qEm2}
                   qAudit3={qAudit3} qTotal={qTotal} showAudit1={showAudit1}>
        </MyitemSRA>
      }
      {(appType === "PISA") &&
        <MyitemPISA no={no} schoolName={schoolName} regencyName={regencyName} provinceName={provinceName}
                    em1={em1} audit1={audit1} audit2={audit2} em2={em2} audit3={audit3}
                    max={max} link={link} sEm1={sEm1} sAudit1={sAudit1} sAudit2={sAudit2} sEm2={sEm2}
                    sAudit3={sAudit3} sMax={sMax} qEm1={qEm1} qAudit1={qAudit1} qAudit2={qAudit2} qEm2={qEm2}
                    qAudit3={qAudit3} qTotal={qTotal} showAudit1={showAudit1} stage={stage} role={role}>
        </MyitemPISA>
      }
    </div>
  );
};

function SchoolsList(props) {
  const [listSchools, setListSchools] = useState(null);
  const [listSchoolStages, setListSchoolStages] = useState(null);
  const history = useHistory();

  const appType = process.env.REACT_APP_APPTYPE;

  const {
    state: {userId, role, token},
  } = useUser();

  let titleInstrument = "";
  let linkString = "";

  switch (role) {
    case "operator":
      titleInstrument = "Peserta";
      linkString = "schoolcategories2";
      break;
    case "auditor":
      titleInstrument = "Auditor";
      linkString = "schoolcategories2";
      break;
    case "viewerpusat":
      titleInstrument = "Viewer Pusat";
      linkString = "schoolcategories2";
      break;
    case "administrator":
      titleInstrument = "Administrator";
      linkString = "schoolcategories2";
      break;
  }

  useEffect(() => {
    // TODO: get all schools --> get authorize schools
    // const data = await axios.get(queryString, {params: {userId: userId, role: role}});
    const url = process.env.REACT_APP_SERVER + "/schoolsmulti";
    axios.get(url, {params: {userId: userId, role: role}})
      .then(
        response => {
          // console.log(response.data.data);
          setListSchools(response.data.data);
          // how if add info for each list schools here?
          console.log(response.data.data);
        }
      )
      .catch(err => {
        console.log(err);
      });
  }, []);

  return (
    <div className={"schoolslist"}>

      {(process.env.REACT_APP_DEV == 1) &&
        <div>
          schoolid:- - stage:- - userid:{userId} - role:{role}
        </div>
      }

      <Typography variant="h5">{`INSTRUMEN STANDARDISASI ${process.env.REACT_APP_TITLE.toUpperCase()}`}</Typography>
      <h2>Beranda</h2>

      {listSchools &&
        listSchools.map(school =>
          <div key={school.school_id}>
            <Myitem no={school.school_id} schoolName={school.school_name}
                    em1={school.total_em_1_score} sEm1={school.em_1_meet_standard}
                    audit1={school.total_audit_1_score} sAudit1={school.audit_1_meet_standard}
                    audit2={school.total_audit_2_score} sAudit2={school.audit_2_meet_standard}
                    em2={school.total_em_2_score} sEm2={school.em_2_meet_standard}
                    audit3={school.total_audit_3_score} sAudit3={school.audit_3_meet_standard}
                    max={school.max_score} sMax={school.standard_min}
                    link={`/${linkString}/${school.school_id.toString()}`}
                    provinceName={school.province_name}
                    regencyName={school.regency_name}
                    appType={appType}
                    stage={school.stage}
                    role={role}
            />
          </div>
        )
      }

    </div>
  );
}

export default SchoolsList;