import React, {useState, useEffect} from 'react';
import {Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle} from "@mui/material";
import * as PropTypes from "prop-types";

import DialogY from "../../components/DialogY";

function MyDialogY(props) {
  const [open, setOpen] = useState(false);
  const [dialogDescription, setDialogDescription] = useState("");
  const [dialogTitle, setDialogTitle] = useState("Login");
  const [linkAction, setLinkAction] = useState(null);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {

  }, [])

  return (
    <div>
      <Button variant="outlined" onClick={() => {
        setDialogDescription("Username & Password salah");
        handleClickOpen();
      }}>
        Open alert dialog
      </Button>
      <DialogY open={open} onAction={() => {
        console.log("ready start finish");
      }} onClose={handleClose} description={dialogDescription} title={dialogTitle}/>
    </div>
  );
}

export default MyDialogY;