import {useState} from "react";
import {UserContext} from "./context";
import useToken from "./useToken";
import useUserId from "./useUserId";
import useRole from "./userRole";

function UserProvider({children}) {

  // const [userId, setUserId] = useState();
  const {userId, setUserId} = useUserId();  // preserve using local storage

  // const [email, setEmail] = useState(""); // ? perlu
  // const [schoolId, setSchoolId] = useState(null); // ? perlu

  // const [role, setRole] = useState("");
  const {role, setRole} = useRole();

  // const [token, setToken] = useState({token: ""});
  const {token, setToken} = useToken(); // preserve using local storage

  // const {stage, setStage} = useState(0); // ? perlu --> get from database
  const value = {
    state: {userId, role, token},
    actions: {setUserId, setRole, setToken}
  };
  return <UserContext.Provider value={value}>{children}</UserContext.Provider>;
}

export default UserProvider;
