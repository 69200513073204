import React from 'react';
import {useState, useEffect} from "react";
import axios from "axios";
import useUser from "../user/use";
import {useHistory} from "react-router-dom";

const RequireAuth = ({children, allowedRoles = []}) => {
  // TODO: is that ok if empty allowedRoles --> not allowed?

  const [haveAccess, setHaveAccess] = useState(false);
  const {
    state: {userId, role, token},
    actions: {setUserId, setRole, setToken},
  } = useUser();

  const history = useHistory();

  const signOut = () => {
    setUserId(0);
    setRole("");
    setToken({token: ""});
  };

  useEffect(() => {
    const checkRole = () => {
      const query2 = process.env.REACT_APP_SERVER + "/anyrole";
      axios.get(query2, {params: {secret_token: token, role: role}})
        .then(response => {
            // console.log("response", response.data);
            // console.log("response.data.message", response.data.message);
            if (response.data.message === "Don't Have Access") {
              // console.log("User/Pass Wrong");
              // setDialogDescription(`Anda tidak mempunyai akses ${role}`);
              // setOpen(true);
              // setToken("");
              history.push("/");
              signOut();
            } else {
              // setToken({token: tempToken});
              // setUserId(tempUserId); // get userId from response
              if (!(allowedRoles.includes(role))) {
                // console.log("don't try");
                history.push("/");
                // return;
                // signOut();
              } else {
                // console.log("you have authorization to access");
                setHaveAccess(true);
              }
            }
          }
        )
        .catch(err => {
          console.log(err);
        });
    };
    checkRole();
  }, [role, setToken, token]);

  return (
    <>
      {haveAccess &&
        <>
          {children}
        </>
      }
    </>
  );
};

export default RequireAuth;