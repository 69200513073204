import {useEffect, useState} from "react";
import axios from "axios";

export const useSchoolInfo = (schoolid) => {
  const [schoolInfo, setSchoolInfo] = useState(null);
  const [schoolAudit1Info, setSchoolAudit1Info] = useState(null);

  useEffect(() => {
    axios.get(process.env.REACT_APP_SERVER + "/schoolinfo?schoolid=" + schoolid)
      .then(response => {
        setSchoolInfo(response.data.data[0]);
        // console.log(response.data.data[0].stage);
        // console.log(response.data.data[0]);
        // console.log(response.data);
        // setSchoolAudit1Info(response.data.dataAudit[0]);
        // console.log(response.data.dataAudit1[0]);
      }).catch(err => console.log(err));
  }, [schoolid]);

  return {schoolInfo};
};
