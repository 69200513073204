import {useState} from "react";

export default function useUserId() {
  const getUserId = () => {
    const userIdString = localStorage.getItem("userId");
    return parseInt(userIdString);
  };

  const [userId, setUserId] = useState(getUserId);

  const saveUserId = (userId) => {
    localStorage.setItem("userId", userId.toString());
    setUserId(parseInt(userId));
  };

  return {
    setUserId: saveUserId,
    userId,
  };
}
