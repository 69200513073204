import React from 'react';

import {Box, Typography} from "@mui/material";

function toTitleCase(str) {
  return str.replace(
    /\w\S*/g,
    function (txt) {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    }
  );
}

function SchoolWidget({schoolInfo}) {
  return (
    <Box sx={{marginTop: "5px"}}>
      <Box sx={{display: "flex"}}>
        <Box sx={{width: 80}}>
          <Typography fontSize={14}>Nama</Typography>
        </Box>
        <Box>
          <Typography fontSize={14}>: {schoolInfo.schoolName}</Typography>
        </Box>
      </Box>
      <Box sx={{display: "flex"}}>
        <Box sx={{width: 80}}>
          <Typography fontSize={14}>Kab/Kota</Typography>
        </Box>
        <Box>
          <Typography fontSize={14}>
            : {toTitleCase(schoolInfo.regencyName)}
          </Typography>
        </Box>
      </Box>
      <Box sx={{display: "flex"}}>
        <Box sx={{width: 80}}>
          <Typography fontSize={14}>Provinsi</Typography>
        </Box>
        <Box>
          <Typography fontSize={14}>: {toTitleCase(schoolInfo.provinceName)}</Typography>
        </Box>
      </Box>
    </Box>
  );
}

export default SchoolWidget;