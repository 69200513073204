import React, {useEffect, useState} from "react";
import {useHistory, useParams} from "react-router-dom";
import axios from "axios";

import Typography from "@mui/material/Typography";

// custom CSS
import "./Em1.css";
// import Em1Component from "../../components/EM1Component";
import QuestionComponent from "../../components/QuestionComponent";
import {useSchoolInfo} from "../../hooks/useSchoolInfo";
import useUser from "../../user/use";
import {Accordion, AccordionDetails, AccordionSummary, Grid} from "@mui/material";
import Button from "@mui/material/Button";
import SchoolWidget from "../../components/SchoolWidget";

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const MyAccordion = ({children, title, defaultExpanded = false}) => {
  return (
    <Accordion defaultExpanded={defaultExpanded}>
      <AccordionSummary expandIcon={<ExpandMoreIcon/>}>{title}</AccordionSummary>
      <AccordionDetails>
        {children}
      </AccordionDetails>
    </Accordion>
  );
};


export default function Em1() {
// get formid & schoolid, from query url, and history for back to previous page
  const {formid, schoolid} = useParams();
  let history = useHistory();
  const {schoolInfo} = useSchoolInfo(schoolid);

  const {
    state: {userId, role, token},
  } = useUser();

  const [data, setData] = useState();
  useEffect(() => {
    axios.get(process.env.REACT_APP_SERVER + "/categoryName", {params: {formid}})
      .then(response => {
          setData(response.data.data[0]);
        }
      )
      .catch(err => {
        console.log(err);
      });
  }, [formid]);

  return (
    <div>
      {schoolInfo &&
        <div>
          <Grid container spacing={2}>
            <Grid item xs={8}>
            </Grid>
            <Grid item xs={4}>
              <SchoolWidget schoolInfo={schoolInfo}></SchoolWidget>
            </Grid>
          </Grid>
        </div>
      }
      {/*<Accordion>*/}
      {/*  <AccordionSummary expandIcon={<ExpandMoreIcon/>}>EM1</AccordionSummary>*/}
      {/*  <AccordionDetails>Detail</AccordionDetails>*/}
      {/*</Accordion>*/}
      {/*<Accordion>*/}
      {/*  <AccordionSummary expandIcon={<ExpandMoreIcon/>}>EM1</AccordionSummary>*/}
      {/*  <AccordionDetails>Detail</AccordionDetails>*/}
      {/*</Accordion>*/}
      {/*<Accordion defaultExpanded={true}>*/}
      {/*  <AccordionSummary expandIcon={<ExpandMoreIcon/>}>EM1</AccordionSummary>*/}
      {/*  <AccordionDetails>Detail</AccordionDetails>*/}
      {/*</Accordion>*/}

      <div className="em1">
        {schoolInfo && data && (schoolInfo && (process.env.REACT_APP_DEV == 1)) &&
          <div>
            schoolid:{schoolid} - stage:{schoolInfo.stage} - userid:{userId} - role:{role}
          </div>
        }
        <Typography variant="h5">{`INSTRUMEN STANDARDISASI ${process.env.REACT_APP_TITLE.toUpperCase()}`}</Typography>
        {schoolInfo && data &&
          <Typography variant="h6">INDIKATOR: {data.category_name} ({data.noshow})</Typography>
        }
        <Button variant="contained" sx={{borderRadius: "0px", boxShadow: "none"}}
                onClick={() => history.goBack()}>Kembali</Button>
        <p></p>

        {/* ------------------------------------------------------------------ */}
        {/* STAGE 0 */}
        {/* TODO: stage 0, what show? */}

        {/* STAGE 1 */}
        {schoolInfo && data && schoolInfo.stage == 1 && role === "operator" &&
          <MyAccordion title={"EVALUASI MANDIRI 1 (EM1)"} defaultExpanded={true}>
            <QuestionComponent formid={formid} schoolid={schoolid} readonly={false} descriptionShow={true}
                               dataSource={"EM1"} descriptionRequired={true} attachmentRequired={true}/>
          </MyAccordion>
        }
        {schoolInfo && data && schoolInfo.stage == 1 && role === "auditor" &&
          <MyAccordion title={"EVALUASI MANDIRI 1 (EM1)"} defaultExpanded={true}>
            <QuestionComponent formid={formid} schoolid={schoolid} readonly={true} descriptionShow={true}
                               dataSource={"EM1"} descriptionRequired={true} attachmentRequired={true}/>
          </MyAccordion>
        }
        {schoolInfo && data && schoolInfo.stage == 1 && (role === "viewerpusat" || role === "administrator") &&
          <MyAccordion title={"EVALUASI MANDIRI 1 (EM1)"} defaultExpanded={true}>
            <QuestionComponent formid={formid} schoolid={schoolid} readonly={true} descriptionShow={true}
                               dataSource={"EM1"} descriptionRequired={true} attachmentRequired={true}/>
          </MyAccordion>
        }

        {/* ------------------------------------------------------------------ */}
        {/* STAGE 2 */}
        {schoolInfo && data && schoolInfo.stage == 2 && role === "operator" &&
          <MyAccordion title={"EVALUASI MANDIRI 1 (EM1)"} defaultExpanded={true}>
            <QuestionComponent formid={formid} schoolid={schoolid} readonly={true} descriptionShow={true}
                               dataSource={"EM1"} descriptionRequired={true} attachmentRequired={true}/>
          </MyAccordion>
        }
        {schoolInfo && data && (schoolInfo.stage == 2) && (role === "auditor") &&
          <div>
            <MyAccordion title={"EVALUASI MANDIRI 1 (EM1)"}>
              <QuestionComponent formid={formid} schoolid={schoolid} readonly={true} descriptionShow={true}
                                 dataSource={"EM1"} descriptionRequired={true} attachmentRequired={true}/>
            </MyAccordion>
            <MyAccordion title={"AUDIT 1"} defaultExpanded={true}>
              <QuestionComponent formid={formid} schoolid={schoolid} readonly={false} descriptionShow={true}
                                 dataSource={"AUDIT1"} descriptionRequired={false} attachmentRequired={false}/>
            </MyAccordion>
          </div>
        }
        {schoolInfo && data && schoolInfo.stage == 2 && (role === "viewerpusat" || role === "administrator") &&
          <div>
            <MyAccordion title={"EVALUASI MANDIRI 1 (EM1)"}>
              <QuestionComponent formid={formid} schoolid={schoolid} readonly={true} descriptionShow={true}
                                 dataSource={"EM1"} descriptionRequired={true} attachmentRequired={true}/>
            </MyAccordion>
            <MyAccordion title={"AUDIT 1"} defaultExpanded={true}>
              <QuestionComponent formid={formid} schoolid={schoolid} readonly={true} descriptionShow={true}
                                 dataSource={"AUDIT1"} descriptionRequired={false} attachmentRequired={false}/>
            </MyAccordion>
          </div>
        }

        {/* ------------------------------------------------------------------ */}
        {/* STAGE 3 */}
        {/* only use by PISA which using audit1 & audit2*/}

        {/*operator*/}


        {/*auditor*/}


        {/* viewer pusat & administrator */}
        {schoolInfo && data && schoolInfo.stage == 3 && (role === "viewerpusat" || role === "administrator") &&
          <div>
            <MyAccordion title={"EVALUASI MANDIRI 1 (EM1)"}>
              <QuestionComponent formid={formid} schoolid={schoolid} readonly={true} descriptionShow={true}
                                 dataSource={"EM1"} descriptionRequired={true} attachmentRequired={true}/>
            </MyAccordion>
            <MyAccordion title={"AUDIT 1"}>
              <QuestionComponent formid={formid} schoolid={schoolid} readonly={true} descriptionShow={true}
                                 dataSource={"AUDIT1"} descriptionRequired={false} attachmentRequired={false}/>
            </MyAccordion>
            <MyAccordion title={"AUDIT 2"} defaultExpanded={true}>
              <QuestionComponent formid={formid} schoolid={schoolid} readonly={true} descriptionShow={true}
                                 dataSource={"AUDIT2"} descriptionRequired={false} attachmentRequired={false}/>
            </MyAccordion>
          </div>
        }

        {/* ------------------------------------------------------------------ */}
        {/* STAGE 4 */}
        {/*operator*/}
        {schoolInfo && data && schoolInfo.stage == 4 && (role === "operator") &&
          <div>
            <MyAccordion title={"EVALUASI MANDIRI 1 (EM1)"}>
              <QuestionComponent formid={formid} schoolid={schoolid} readonly={true} descriptionShow={true}
                                 dataSource={"EM1"} descriptionRequired={true} attachmentRequired={true}/>
            </MyAccordion>
            <MyAccordion title={"AUDIT 1"}>
              <QuestionComponent formid={formid} schoolid={schoolid} readonly={true} descriptionShow={true}
                                 dataSource={"AUDIT1"} descriptionRequired={false} attachmentRequired={false}/>
            </MyAccordion>
            {/*<MyAccordion title={"AUDIT 2"}>*/}
            {/*  <QuestionComponent formid={formid} schoolid={schoolid} readonly={false} descriptionShow={true}*/}
            {/*                     dataSource={"AUDIT2"} descriptionRequired={false} attachmentRequired={false}/>*/}
            {/*</MyAccordion>*/}
            <MyAccordion title={"EVALUASI MANDIRI 2 (EM2)"} defaultExpanded={true}>
              <QuestionComponent formid={formid} schoolid={schoolid} readonly={false} descriptionShow={true}
                                 dataSource={"EM2"} descriptionRequired={false} attachmentRequired={false}/>
            </MyAccordion>
            {/* todo: temporary solution for repairing em2 after audit final */}
            {/*<MyAccordion title={"AUDIT FINAL"} defaultExpanded={true}>*/}
            {/*  <QuestionComponent formid={formid} schoolid={schoolid} readonly={true} descriptionShow={true}*/}
            {/*                     dataSource={"AUDIT3"} descriptionRequired={false} attachmentRequired={false}/>*/}
            {/*</MyAccordion>*/}
          </div>
        }

        {/* viewer pusat & administrator */}


        {/* ------------------------------------------------------------------ */}
        {/* STAGE 5 */}
        {/*operator*/}
        {schoolInfo && data && schoolInfo.stage == 5 && (role === "operator") &&
          <div>
            <MyAccordion title={"EVALUASI MANDIRI 1 (EM1)"}>
              <QuestionComponent formid={formid} schoolid={schoolid} readonly={true} descriptionShow={true}
                                 dataSource={"EM1"} descriptionRequired={true} attachmentRequired={true}/>
            </MyAccordion>
            <MyAccordion title={"AUDIT 1"}>
              <QuestionComponent formid={formid} schoolid={schoolid} readonly={true} descriptionShow={true}
                                 dataSource={"AUDIT1"} descriptionRequired={false} attachmentRequired={false}/>
            </MyAccordion>
            {/*<MyAccordion title={"AUDIT 2"}>*/}
            {/*  <QuestionComponent formid={formid} schoolid={schoolid} readonly={false} descriptionShow={true}*/}
            {/*                     dataSource={"AUDIT2"} descriptionRequired={false} attachmentRequired={false}/>*/}
            {/*</MyAccordion>*/}
            <MyAccordion title={"EVALUASI MANDIRI 2 (EM2)"} defaultExpanded={true}>
              <QuestionComponent formid={formid} schoolid={schoolid} readonly={true} descriptionShow={true}
                                 dataSource={"EM2"} descriptionRequired={false} attachmentRequired={false}/>
            </MyAccordion>
            {/* todo: temporary solution for repairing em2 after audit final */}
            {/*<MyAccordion title={"AUDIT FINAL"} defaultExpanded={true}>*/}
            {/*  <QuestionComponent formid={formid} schoolid={schoolid} readonly={true} descriptionShow={true}*/}
            {/*                     dataSource={"AUDIT3"} descriptionRequired={false} attachmentRequired={false}/>*/}
            {/*</MyAccordion>*/}
          </div>
        }

        {/* ------------------------------------------------------------------ */}
        {/* STAGE 7 */}
        {/* Remedial */}
        {/*operator*/}
        {/* viewer pusat & administrator */}
        {schoolInfo && data && schoolInfo.stage == 7 && (role === "operator") &&
          <div>
            <MyAccordion title={"EVALUASI MANDIRI 1 (EM1)"}>
              <QuestionComponent formid={formid} schoolid={schoolid} readonly={true} descriptionShow={true}
                                 dataSource={"EM1"} descriptionRequired={true} attachmentRequired={true}/>
            </MyAccordion>
            <MyAccordion title={"AUDIT 1"}>
              <QuestionComponent formid={formid} schoolid={schoolid} readonly={true} descriptionShow={true}
                                 dataSource={"AUDIT1"} descriptionRequired={false} attachmentRequired={false}/>
            </MyAccordion>
            {/*<MyAccordion title={"AUDIT 2"}>*/}
            {/*  <QuestionComponent formid={formid} schoolid={schoolid} readonly={false} descriptionShow={true}*/}
            {/*                     dataSource={"AUDIT2"} descriptionRequired={false} attachmentRequired={false}/>*/}
            {/*</MyAccordion>*/}
            <MyAccordion title={"EVALUASI MANDIRI 2 (EM2)"} defaultExpanded={true}>
              <QuestionComponent formid={formid} schoolid={schoolid} readonly={false} descriptionShow={true}
                                 dataSource={"EM2"} descriptionRequired={false} attachmentRequired={false}/>
            </MyAccordion>
            {/* todo: temporary solution for repairing em2 after audit final */}
            <MyAccordion title={"AUDIT FINAL"} defaultExpanded={true}>
              <QuestionComponent formid={formid} schoolid={schoolid} readonly={true} descriptionShow={true}
                                 dataSource={"AUDIT3"} descriptionRequired={false} attachmentRequired={false}/>
            </MyAccordion>
          </div>
        }

        {/* ------------------------------------------------------------------ */}
        {/*auditor 1*/}
        {schoolInfo && data && schoolInfo.stage == 4 && (role === "auditor") &&
          <div>
            <MyAccordion title={"EVALUASI MANDIRI 1 (EM1)"}>
              <QuestionComponent formid={formid} schoolid={schoolid} readonly={true} descriptionShow={true}
                                 dataSource={"EM1"} descriptionRequired={true} attachmentRequired={true}/>
            </MyAccordion>
            <MyAccordion title={"AUDIT 1"}>
              <QuestionComponent formid={formid} schoolid={schoolid} readonly={true} descriptionShow={true}
                                 dataSource={"AUDIT1"} descriptionRequired={false} attachmentRequired={false}/>
            </MyAccordion>
            {/*<MyAccordion title={"AUDIT 2"}>*/}
            {/*  <QuestionComponent formid={formid} schoolid={schoolid} readonly={true} descriptionShow={true}*/}
            {/*                     dataSource={"AUDIT2"} descriptionRequired={false} attachmentRequired={false}/>*/}
            {/*</MyAccordion>*/}
            <MyAccordion title={"EVALUASI MANDIRI 2 (EM2)"} defaultExpanded={true}>
              <QuestionComponent formid={formid} schoolid={schoolid} readonly={true} descriptionShow={true}
                                 dataSource={"EM2"} descriptionRequired={false} attachmentRequired={false}/>
            </MyAccordion>
          </div>
        }

        {/*auditor 2*/}

        {/*auditor 3*/}

        {/* viewer pusat & administrator */}
        {schoolInfo && data && schoolInfo.stage == 4 && (role === "viewerpusat" || role === "administrator") &&
          <div>
            <MyAccordion title={"EVALUASI MANDIRI 1 (EM1)"}>
              <QuestionComponent formid={formid} schoolid={schoolid} readonly={true} descriptionShow={true}
                                 dataSource={"EM1"} descriptionRequired={true} attachmentRequired={true}/>
            </MyAccordion>
            <MyAccordion title={"AUDIT 1"}>
              <QuestionComponent formid={formid} schoolid={schoolid} readonly={true} descriptionShow={true}
                                 dataSource={"AUDIT1"} descriptionRequired={false} attachmentRequired={false}/>
            </MyAccordion>
            {/*<MyAccordion title={"AUDIT 2"}>*/}
            {/*  <QuestionComponent formid={formid} schoolid={schoolid} readonly={true} descriptionShow={true}*/}
            {/*                     dataSource={"AUDIT2"} descriptionRequired={false} attachmentRequired={false}/>*/}
            {/*</MyAccordion>*/}
            <MyAccordion title={"EVALUASI MANDIRI 2 (EM2)"} defaultExpanded={true}>
              <QuestionComponent formid={formid} schoolid={schoolid} readonly={true} descriptionShow={true}
                                 dataSource={"EM2"} descriptionRequired={false} attachmentRequired={false}/>
            </MyAccordion>
          </div>
        }

        {/* ------------------------------------------------------------------ */}
        {/* STAGE 5 */}

        {/*operator*/}


        {/*auditor 1*/}
        {schoolInfo && data && schoolInfo.stage == 5 && (role === "auditor") &&
          <div>
            <MyAccordion title={"EVALUASI MANDIRI 1 (EM1)"}>
              <QuestionComponent formid={formid} schoolid={schoolid} readonly={true} descriptionShow={true}
                                 dataSource={"EM1"} descriptionRequired={true} attachmentRequired={true}/>
            </MyAccordion>
            <MyAccordion title={"AUDIT 1"}>
              <QuestionComponent formid={formid} schoolid={schoolid} readonly={true} descriptionShow={true}
                                 dataSource={"AUDIT1"} descriptionRequired={false} attachmentRequired={false}/>
            </MyAccordion>
            {/*<MyAccordion title={"AUDIT 2"}>*/}
            {/*  <QuestionComponent formid={formid} schoolid={schoolid} readonly={true} descriptionShow={true}*/}
            {/*                     dataSource={"AUDIT2"} descriptionRequired={false} attachmentRequired={false}/>*/}
            {/*</MyAccordion>*/}
            <MyAccordion title={"EVALUASI MANDIRI 2 (EM2)"}>
              <QuestionComponent formid={formid} schoolid={schoolid} readonly={true} descriptionShow={true}
                                 dataSource={"EM2"} descriptionRequired={false} attachmentRequired={false}/>
            </MyAccordion>
            <MyAccordion title={"AUDIT FINAL"} defaultExpanded={true}>
              <QuestionComponent formid={formid} schoolid={schoolid} readonly={false} descriptionShow={true}
                                 dataSource={"AUDIT3"} descriptionRequired={false} attachmentRequired={false}/>
            </MyAccordion>
          </div>
        }

        {/*auditor 2*/}

        {/*auditor 3*/}


        {/* viewer pusat & administrator */}
        {schoolInfo && data && schoolInfo.stage == 5 && (role === "viewerpusat" || role === "administrator") &&
          <div>
            <MyAccordion title={"EVALUASI MANDIRI 1 (EM1)"}>
              <QuestionComponent formid={formid} schoolid={schoolid} readonly={true} descriptionShow={true}
                                 dataSource={"EM1"} descriptionRequired={true} attachmentRequired={true}/>
            </MyAccordion>
            <MyAccordion title={"AUDIT 1"}>
              <QuestionComponent formid={formid} schoolid={schoolid} readonly={true} descriptionShow={true}
                                 dataSource={"AUDIT1"} descriptionRequired={false} attachmentRequired={false}/>
            </MyAccordion>
            {/*<MyAccordion title={"AUDIT 2"}>*/}
            {/*  <QuestionComponent formid={formid} schoolid={schoolid} readonly={true} descriptionShow={true}*/}
            {/*                     dataSource={"AUDIT2"} descriptionRequired={false} attachmentRequired={false}/>*/}
            {/*</MyAccordion>*/}
            <MyAccordion title={"EVALUASI MANDIRI 2 (EM2)"}>
              <QuestionComponent formid={formid} schoolid={schoolid} readonly={true} descriptionShow={true}
                                 dataSource={"EM2"} descriptionRequired={false} attachmentRequired={false}/>
            </MyAccordion>
            <MyAccordion title={"AUDIT FINAL"} defaultExpanded={true}>
              <QuestionComponent formid={formid} schoolid={schoolid} readonly={true} descriptionShow={true}
                                 dataSource={"AUDIT3"} descriptionRequired={false} attachmentRequired={false}/>
            </MyAccordion>
          </div>
        }

        {/* ------------------------------------------------------------------ */}
        {/* STAGE 6 */}

        {/*operator*/}


        {/*auditor 1*/}

        {/*auditor 2*/}

        {/*auditor 3*/}


        {/* viewer pusat & administrator */}
        {schoolInfo && data && (schoolInfo.stage == 6 || schoolInfo.stage == 7) && (role === "viewerpusat" || role === "administrator") &&
          <div>
            <MyAccordion title={"EVALUASI MANDIRI 1 (EM1)"}>
              <QuestionComponent formid={formid} schoolid={schoolid} readonly={true} descriptionShow={true}
                                 dataSource={"EM1"} descriptionRequired={true} attachmentRequired={true}/>
            </MyAccordion>
            <MyAccordion title={"AUDIT 1"}>
              <QuestionComponent formid={formid} schoolid={schoolid} readonly={true} descriptionShow={true}
                                 dataSource={"AUDIT1"} descriptionRequired={false} attachmentRequired={false}/>
            </MyAccordion>
            {/*<MyAccordion title={"AUDIT 2"}>*/}
            {/*  <QuestionComponent formid={formid} schoolid={schoolid} readonly={true} descriptionShow={true}*/}
            {/*                     dataSource={"AUDIT2"} descriptionRequired={false} attachmentRequired={false}/>*/}
            {/*</MyAccordion>*/}
            <MyAccordion title={"EVALUASI MANDIRI 2 (EM2)"}>
              <QuestionComponent formid={formid} schoolid={schoolid} readonly={true} descriptionShow={true}
                                 dataSource={"EM2"} descriptionRequired={false} attachmentRequired={false}/>
            </MyAccordion>
            <MyAccordion title={"AUDIT FINAL"} defaultExpanded={true}>
              <QuestionComponent formid={formid} schoolid={schoolid} readonly={true} descriptionShow={true}
                                 dataSource={"AUDIT3"} descriptionRequired={false} attachmentRequired={false}/>
            </MyAccordion>
          </div>
        }


        {/* ------------------------------------------------------------------ */}

        <p></p>
        <Button variant="contained" onClick={() => history.goBack()}>Kembali</Button>
      </div>
    </div>
  );
}
